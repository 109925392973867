/*
 *
 * Dashboard
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setTitle, setBreadcrumb, delayedDispatch, updateCrumb, setLoader } from "store/actions";
import { ContentWrapper, Icon, Table, WorksheetCard } from "components";
import { Row, Col, Card, notification, Modal, Input } from "antd";
import { Line } from "@ant-design/charts";
import moment from "moment";
import { DateTime } from "luxon";

import Strings from "utils/strings";
import "./styles.scss";
import { API, Endpoints } from "utils/api";

export class Dashboard extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			hasUnsavedFields: true,
			startDate: moment().startOf("month"),
			endDate: moment().endOf("day"),
			language: "pt",
			data: {
				kmStatistics: [],
				hoursStatistics: [],
				fuelStatistics: []
			},
			staffs: [],
			staff: null,
			vehicles: [],
			vehicle: null,
			customers: [],
			customer: null,
			cards: {
				workValues: {
					title: Strings.dashboard.workValues,
					icon: "expenses",
					type: "workValues",
					value: 0,
					isMonetary: true
				},
				fuelValue: {
					title: Strings.dashboard.fuelUse,
					icon: "expenses",
					type: "fuelValues",
					value: 0,
					isMonetary: true
				},
				costsUse: {
					title: Strings.dashboard.costsUse,
					icon: "expenses",
					type: "costsValues",
					value: 0,
					isMonetary: true
				},
				extraWorksUse: {
					title: Strings.dashboard.extraWorksUse,
					icon: "expenses",
					type: "extraWorksValues",
					value: 0,
					isMonetary: true
				},
				kmUse: {
					title: Strings.dashboard.kmUse,
					icon: "truck",
					type: "kmValues",
					value: 0,
					isMonetary: false
				},
				hoursUse: {
					title: Strings.dashboard.hoursUse,
					icon: "speedometer",
					type: "hoursValues",
					value: 0,
					isMonetary: false
				},
				
				fuelLiters: {
					title: Strings.dashboard.fuelLiters,
					icon: "truck",
					type: "fuelLiters",
					value: 0,
					isMonetary: false
				},
			},
			worksheet: []
		};

		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.dashboard));

		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate } = this.state;
				const { user } = this.props;

				const actions = [] as Array<any>;
				if (user?.role === "driver" || user?.role === "helper" || user?.role === "mechanic") {
					actions.push({
						type: "datePicker",
						text: Strings.dashboard.period,
						dates: [startDate, endDate],
						onChange: this.handleSelect,
						className: "fixedPicker"
					});
				} else {
					actions.push(
						{
							type: "select",
							value: this.state.staff,
							options: this.state.staffs?.map((entry: any) => ({
								text: entry.name,
								value: entry._id
							})),
							allowClear: true,
							showSearch: true,
							onChange: (staff: any) => {
								this.setState(
									{
										staff
									},
									() => this.getData(true)
								);
							},
							placeholder: Strings.staff.selectStaff,
							minWidth: 180
						},
						{
							type: "select",
							separator: "left",
							value: this.state.vehicle,
							options: this.state.vehicles?.map((entry: any) => ({
								text: entry.licensePlate,
								value: entry._id
							})),
							allowClear: true,
							showSearch: true,
							onChange: (vehicle: any) => {
								this.setState(
									{
										vehicle
									},
									() => this.getData(true)
								);
							},
							placeholder: Strings.dashboard.selectVehicle,
							minWidth: 180
						},
						{
							type: "label",
							text: Strings.dashboard.selectVehicleNumber,
							separator: "left",
							value: this.state.vehicleNumber,
							onChange: (e: any) => {
								this.setState(
									{
										vehicleNumber: e.target.value
									},
									() => this.getData()
								);
							},
							minWidth: 180
						},
						{
							type: "select",
							separator: "left",
							value: this.state.customer,
							options: this.state.customers?.map((entry: any) => ({
								text: entry.name,
								value: entry._id
							})),
							allowClear: true,
							showSearch: true,
							onChange: (customer: any) => {
								this.setState(
									{
										customer
									},
									() => this.getData(true)
								);
							},
							placeholder: Strings.dashboard.selectCustomer,
							minWidth: 180
						},
						{
							type: "datePicker",
							separator: "left",
							text: Strings.dashboard.period,
							dates: [startDate, endDate],
							onChange: this.handleSelect,
							className: "fixedPicker"
						}
					);
				}

				return { actions };
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	handleSelect(dates: any) {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData(true)
		);
	}

	getData = async (notToWait: any = false) => {
		const { startDate, endDate, cards, staff, vehicle, customer, vehicleNumber } = this.state;
		const { dispatch, user } = this.props;


		setTimeout(
			async () => {
				dispatch(setLoader(true));

				try {
					if (user?.role === "driver" || user?.role === "helper") {
						const body = {
							startDate: startDate.format("DD/MM/yyyy"),
							endDate: endDate.format("DD/MM/yyyy"),
						};

						const [response, finishedWorksResponse] = await Promise.all([
							API.get({
								url: Endpoints.uriWorksheets()
							}),
							API.post({
								url: Endpoints.uriAnalytics(),
								data: body
							})
						]);

						if (response.ok && finishedWorksResponse?.ok) {
							const { worksheet = [] } = response.data.results || {};
							const { finishedWorksResume = [] } = finishedWorksResponse.data.results || {};
							

							this.setState({ worksheet, finishedWorksResume });
						} else {
							notification.error({
								message: Strings.serverErrors.title,
								description: response.data?.message || Strings.serverErrors.wentWrong,
								placement: "bottomRight",
								duration: 5
							});
						}
					} else {
						const body = {
							startDate: startDate.format("DD/MM/yyyy"),
							endDate: endDate.format("DD/MM/yyyy"),
							staff,
							vehicle,
							customer,
							vehicleNumber,
						};

						const [response, worksheetResponse] = await Promise.all([
							API.post({
								url: Endpoints.uriAnalytics(),
								data: body
							}),
							API.get({
								url: Endpoints.uriWorksheets()
							})
						]);

						if (response?.ok && worksheetResponse?.ok) {
							const { analytics, staffs, vehicles, customers } = response.data.results || {};
							const { worksheet = [] } = worksheetResponse.data.results || {};

							let diff = endDate.diff(startDate, "days") + 1;
							const format = diff > 90 ? "MM/yyyy" : "DD/MM/yyyy";

							const kmStatistics = analytics?.kmValues?.byDate?.map((elem: number, index: number) => ({
								day: moment(startDate)
									.add(index, diff > 90 ? "months" : "days")
									.format(format),
								name: Strings.dashboard.kmDetails,
								value: elem
							}));

							const hoursStatistics = analytics?.hoursValues?.byDate?.map((elem: number, index: number) => ({
								day: moment(startDate)
									.add(index, diff > 90 ? "months" : "days")
									.format(format),
								name: Strings.dashboard.hoursDetails,
								value: elem
							}));

							const fuelStatistics = analytics?.fuelValues?.byDate?.map((elem: number, index: number) => ({
								day: moment(startDate)
									.add(index, diff > 90 ? "months" : "days")
									.format(format),
								name: Strings.worksheets.amount,
								value: elem
							}));
							fuelStatistics.push(
								...analytics.fuelValues.byDateLiters.map((elem: number, index: number) => ({
									day: moment(startDate)
										.add(index, diff > 90 ? "months" : "days")
										.format(format),
									name: Strings.worksheets.liters,
									value: elem
								}))
							);

							const costsStatistics = analytics?.costsValues?.byDate?.map((elem: number, index: number) => ({
								day: moment(startDate)
									.add(index, diff > 90 ? "months" : "days")
									.format(format),
								name: Strings.dashboard.costsDetails,
								value: elem
							}));
							const workValuesStatistics = analytics?.workValues?.byDate?.map((elem: number, index: number) => ({
								day: moment(startDate)
									.add(index, diff > 90 ? "months" : "days")
									.format(format),
								name: Strings.dashboard.workValues,
								value: elem
							}));

							const newCards = JSON.parse(JSON.stringify(cards));

							newCards.hoursUse.value = analytics?.hoursValues?.value?.toFixed(0);
							newCards.kmUse.value = analytics?.kmValues?.value?.toFixed(2);
							newCards.fuelValue.value = analytics?.fuelValues?.value?.toFixed(2);
							newCards.fuelLiters.value = analytics?.fuelValues?.liters?.toFixed(2);
							newCards.costsUse.value = analytics?.costsValues?.value?.toFixed(2);
							newCards.extraWorksUse.value = analytics?.extraWorksValues?.value?.toFixed(2);
							newCards.workValues.value = analytics?.workValues?.value?.toFixed(2);

							this.setState({
								data: { kmStatistics, hoursStatistics, fuelStatistics, costsStatistics, workValuesStatistics },
								cards: newCards,
								kmValues: analytics?.kmValues?.value,
								hoursValues: analytics?.hoursValues?.value,
								fuelValues: analytics?.fuelValues?.value,
								litersValues: analytics?.fuelValues?.liters,
								costsValues: analytics?.costsValues?.value,
								workValues: analytics?.workValues?.value,
								extraWorksValues: analytics?.extraWorksValues?.value,
								costsResume: analytics?.costsValues?.resume,
								fuelResume: analytics?.fuelValues?.resume,
								workValuesResume: analytics?.workValues?.resume,
								extraWorksResume: analytics?.extraWorksValues?.resume,
								revenueAnalysis: analytics?.revenueAnalysis,
								staffs,
								vehicles,
								customers,
								worksheet
							});
						} else {
							notification.error({
								message: Strings.serverErrors.title,
								description: response.data?.message || Strings.serverErrors.wentWrong,
								placement: "bottomRight",
								duration: 5
							});
						}
					}
				} catch (err) {
					console.log("Error", err);
					// notification.error({
					// 	message: Strings.serverErrors.title,
					// 	description: (err as string) || Strings.serverErrors.wentWrong,
					// 	placement: "bottomRight",
					// 	duration: 5
					// });
				}

				dispatch(setLoader(false));
			},
			notToWait ? 0 : 1000
		);

		
	}

	async changeWorksheet() {
		const { tempWorksheet } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const body = { 
				liters: tempWorksheet?.liters || 0,
				value: tempWorksheet?.value || 0,
				customerNumber: tempWorksheet?.customerNumber || '',
				vehicleNumber: tempWorksheet?.vehicleNumber || '',
				valueExtra: tempWorksheet?.valueExtra || 0,
				litersAdBlue: tempWorksheet?.litersAdBlue || 0,
				valueAdBlue: tempWorksheet?.valueAdBlue || 0,
			} as any;

			const url = tempWorksheet?.costId || tempWorksheet?.fuelId ? 
				Endpoints.uriWorksheets(`values/${tempWorksheet?._id}/${tempWorksheet?.costId ? 'cost' : 'fuel'}/${tempWorksheet?.costId || tempWorksheet?.fuelId}`) :
				Endpoints.uriWorksheets(`values/${tempWorksheet?._id}`)

			const response = await API.patch({
				url: url,
				data: body
			});

			if (response.ok) {
				this.setState({ showValueModal: null, tempWorksheet: null })
				
				await this.getData();

				notification.success({
					message: Strings.worksheets.worksheets,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderPendingWorksheets() {
		const { worksheet = [] } = this.state;
		const { user } = this.props;

		if (worksheet.length === 0) return null;

		return (
			<React.Fragment>
				<Row gutter={[20, 20]} style={{ paddingBottom: 40 }}>
					{((user?.role === "driver" || user?.role === "helper") && worksheet.length === 0 && (
						<Col key={`worksheet_${worksheet._id}`} xs={24}>
							<p>
								<strong>{Strings.worksheets.noneAvailable}</strong>
							</p>
						</Col>
					)) ||
						null}
					{worksheet.map((worksheet: any) => {
						return (
							<Col key={`worksheet_${worksheet._id}`} xs={24} md={12} xl={8}>
								<WorksheetCard worksheet={worksheet} />
							</Col>
						);
					})}
				</Row>
			</React.Fragment>
		);
	}

	renderDriverDashboard() {
		const { finishedWorksResume } = this.state;

		// isDriver: 1,

		return (
			<React.Fragment>
				{this.renderPendingWorksheets()}
				<ContentWrapper>
					<Table
						title={{
							icon: "speedometer",
							title: Strings.dashboard.myFinishedWorks
						}}
						data={finishedWorksResume}
						columns={[
							{
								Header: Strings.staff.type,
								id: "isDriver",
								accessor: (row: any) => row.isDriver ? Strings.staff.driver : Strings.staff.helper,
							},
							{
								Header: Strings.worksheets.number,
								id: "number",
								accessor: (row: any) => row.number
							},
							{
								Header: Strings.fields.date,
								id: "date",
								accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy")
							},
							{
								Header: Strings.vehicles.vehicle,
								id: "vehicle",
								minWidth: 50,
								accessor: (row: any) => row.vehicle || "-"
							},
							{
								Header: Strings.worksheets.exitHour,
								id: "exitHour",
								minWidth: 70,
								accessor: (row: any) =>
									(row.exitHour && DateTime.fromISO(row.exitHour).toFormat("HH:mm")) || "-"
							},
							{
								Header: Strings.worksheets.returnHour,
								id: "returnHour",
								minWidth: 70,
								accessor: (row: any) =>
									(row.returnHour && DateTime.fromISO(row.returnHour).toFormat("HH:mm")) || "-"
							},
							{
								Header: Strings.customers.customer,
								id: "customer",
								accessor: (row: any) => row.customer || '-'
							},
							{
								Header: Strings.worksheets.origin,
								id: "origin",
								accessor: (row: any) => row.origin || '-'
							},
							{
								Header: Strings.worksheets.destination,
								id: "destination",
								accessor: (row: any) => row.destination || '-'
							},
							{
								Header: Strings.worksheets.exitKm,
								id: "exitKm",
								minWidth: 50,
								accessor: (row: any) => row.exitKm || "-"
							},
							{
								Header: Strings.worksheets.returnKm,
								id: "returnKm",
								minWidth: 50,
								accessor: (row: any) => row.returnKm || "-"
							},
							{
								Header: Strings.worksheets.totalKm,
								id: "km",
								minWidth: 50,
								accessor: (row: any) => row.km || "-"
							},
						]}
						isSinglePage
						fullPage
						paginated={false}
					/>
				</ContentWrapper>
			</React.Fragment>
		);
	}

	renderValueModal() {
        const { showValueModal, tempWorksheet} = this.state;

		if (tempWorksheet?.fuelId) {
			return (
				<Modal
					centered
					width="500px"
					visible={!!showValueModal}
					cancelButtonProps={{ className: "displayNone" }}
					okButtonProps={{ className: "displayNone" }}
					onCancel={() => {
						this.setState({ showValueModal: null, tempWorksheet: null });
					}}
					onOk={async () => {
						this.setState({ showValueModal: null });
						await this.changeWorksheet();
					}}
				>
					<div className="title" style={{ paddingBottom: 20, paddingTop: 20 }}>
						{`Indique os valores do abastecimento da viatura ${tempWorksheet?.vehicle} no trabalho ${tempWorksheet?.number} na data ${DateTime.fromISO(tempWorksheet?.date).toFormat("dd/MM/yyyy")}`}
					</div>
					<Row gutter={[24, 24]}>
						<Col xs={12}>
							<label htmlFor="fuel_liters" className="InputLabel">
								{Strings.worksheets.liters}
							</label>
							<Input
								id="value"
								type="number"
								value={tempWorksheet?.liters}
								placeholder={Strings.worksheets.liters}
								addonAfter="€"
								onChange={(e: any) => {
									const liters = e.target.value;
									this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, liters }}));
								}}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="fuel_value" className="InputLabel">
								{Strings.worksheets.amount}
							</label>
							<Input
								id="value"
								type="number"
								value={tempWorksheet?.value}
								placeholder={Strings.worksheets.amount}
								addonAfter="€"
								onChange={(e: any) => {
									const value = e.target.value;
									this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, value }}));
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={12}>
							<label htmlFor="fuel_litersAdBlue" className="InputLabel">
								{Strings.worksheets.litersAdBlue}
							</label>
							<Input
								id="value"
								type="number"
								value={tempWorksheet?.litersAdBlue}
								placeholder={Strings.worksheets.litersAdBlue}
								addonAfter="€"
								onChange={(e: any) => {
									const litersAdBlue = e.target.value;
									this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, litersAdBlue }}));
								}}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="fuel_value" className="InputLabel">
								{Strings.worksheets.amountAdBlue}
							</label>
							<Input
								id="value"
								type="number"
								value={tempWorksheet?.valueAdBlue}
								placeholder={Strings.worksheets.amountAdBlue}
								addonAfter="€"
								onChange={(e: any) => {
									const valueAdBlue = e.target.value;
									this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, valueAdBlue }}));
								}}
							/>
						</Col>
					</Row>
				</Modal>
			);
		}

		if (tempWorksheet?.costId) {
			return (
				<Modal
					centered
					width="500px"
					visible={!!showValueModal}
					cancelButtonProps={{ className: "displayNone" }}
					okButtonProps={{ className: "displayNone" }}
					onCancel={() => {
						this.setState({ showValueModal: null, tempWorksheet: null });
					}}
					onOk={async () => {
						this.setState({ showValueModal: null });
						await this.changeWorksheet();
					}}
				>
					<div className="title" style={{ paddingBottom: 20, paddingTop: 20 }}>
					{ tempWorksheet?.costId ? 
						( <div>
							Indique o valor do custo {tempWorksheet?.cost} no trabalho {tempWorksheet?.number} na data {DateTime.fromISO(tempWorksheet?.date).toFormat("dd/MM/yyyy")}
							{tempWorksheet?.notes && (
							<div>
								<strong>NOTAS:</strong> {tempWorksheet?.notes}
							</div>
							)}
						</div> ) : 
						`Indique o valor a faturar ao cliente ${tempWorksheet?.customer} no trabalho ${tempWorksheet?.number} na data ${DateTime.fromISO(tempWorksheet?.date).toFormat("dd/MM/yyyy")}`
					}
						
					</div>
					<Col xs={24}>
						<Input
							id="value"
							type="number"
							value={tempWorksheet?.value}
							placeholder={Strings.worksheets.amount}
							addonAfter="€"
							onChange={(e: any) => {
								const value = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, value }}));
							}}
						/>
					</Col>
				</Modal>
			);
		}
        
        return (
			<Modal
				centered
				width="500px"
				visible={!!showValueModal}
				cancelButtonProps={{ className: "displayNone" }}
				okButtonProps={{ className: "displayNone" }}
				onCancel={() => {
					this.setState({ showValueModal: null, tempWorksheet: null });
				}}
				onOk={async () => {
					this.setState({ showValueModal: null });
					await this.changeWorksheet();
				}}
			>
				<div className="title" style={{ paddingBottom: 20, paddingTop: 20 }}>
					{`Indique o valor a faturar ao cliente ${tempWorksheet?.customer} no trabalho ${tempWorksheet?.number} na data ${DateTime.fromISO(tempWorksheet?.date).toFormat("dd/MM/yyyy")}`}
				</div>
				<Row gutter={[24, 24]}>
					<Col xs={12}>
						<label htmlFor="value" className="InputLabel">
							{Strings.worksheets.workValue}
						</label>
						<Input
							id="value"
							type="number"
							value={tempWorksheet?.value}
							placeholder={Strings.worksheets.workValue}
							addonAfter="€"
							onChange={(e: any) => {
								const value = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, value }}));
							}}
						/>
					</Col>
					<Col xs={12}>
						<label htmlFor="valueExtra" className="InputLabel">
							{Strings.worksheets.workValueExtra}
						</label>
						<Input
							id="valueExtra"
							type="number"
							value={tempWorksheet?.valueExtra}
							placeholder={Strings.worksheets.workValueExtra}
							addonAfter="€"
							onChange={(e: any) => {
								const valueExtra = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, valueExtra }}));
							}}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 24]}>
					<Col xs={12}>
						<label htmlFor="customerNumber" className="InputLabel">
							{Strings.worksheets.customerNumber}
						</label>
						<Input
							id="customerNumber"
							value={tempWorksheet?.customerNumber}
							placeholder={Strings.worksheets.customerNumber}
							onChange={(e: any) => {
								const customerNumber = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, customerNumber }}));
							}}
						/>
					</Col>
					<Col xs={12}>
						<label htmlFor="vehicleNumber" className="InputLabel">
							{Strings.worksheets.vehicleNumber}
						</label>
						<Input
							id="vehicleNumber"
							value={tempWorksheet?.vehicleNumber}
							placeholder={Strings.worksheets.vehicleNumber}
							onChange={(e: any) => {
								const vehicleNumber = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, vehicleNumber }}));
							}}
						/>
					</Col>
				</Row>
			</Modal>
		);
    }

	renderAdminDashboard() {
		const { data, cards, costsResume, fuelResume, extraWorksResume, workValuesResume, revenueAnalysis, customer } = this.state;

		const config = (type: string) => {
			const graphData =
				type === "kmStatistics"
					? data.kmStatistics || []
					: type === "hoursStatistics"
					? data.hoursStatistics || []
					: type === "fuelStatistics"
					? data.fuelStatistics || []
					: type === "costsStatistics"
					? data.costsStatistics || []
					: data.workValuesStatistics || []

			return {
				data: graphData,
				xField: "day",
				yField: "value",
				seriesField: "name",
				yAxis: {
					label: {
						formatter: function formatter(v: string) {
							return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
								return "".concat(s, ",");
							});
						}
					},
					visible: true,
					min: 0
				},
				// height: 450,
				// autoFit: false,
				appendPadding: 10,
				// legend: { position: "top" },
				// renderer: "svg",
				smooth: true,
				animation: {
					appear: {
						animation: "path-in",
						duration: 2500
					}
				}
			};
		};

		const columnsCosts = [
			{
				Header: Strings.customers.customer,
				id: "customer",
				accessor: (row: any) => row.customer
			},
			{
				Header: Strings.vehicles.licensePlate,
				id: "vehicle",
				accessor: (row: any) => row.vehicle
			},
			{
				Header: Strings.worksheets.number,
				id: "number",
				accessor: (row: any) => row.number
			},
			{
				Header: Strings.fields.date,
				id: "date",
				accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy")
			},
			{
				Header: Strings.fields.category,
				id: "name",
				accessor: (row: any) => row.cost
			},
			{
				Header: Strings.fields.notes,
				id: "notes",
				accessor: (row: any) => row.notes
			},
			{
				Header: Strings.worksheets.entry,
				id: "entry",
				accessor: (row: any) => row.entry
			},
			{
				Header: Strings.worksheets.exit,
				id: "exit",
				accessor: (row: any) => row.exit
			},
			{
				Header: Strings.fields.total,
				id: "value",
				align: "right",
				accessor: (row: any) => `${row.value?.toFixed(2) || '-'} €`
			}
		] as Array<any>;

		if (customer) columnsCosts.shift();
		
		const columnsFuel = [
			{
				Header: Strings.customers.customer,
				id: "customer",
				accessor: (row: any) => row.customer
			},
			{
				Header: Strings.vehicles.vehicle,
				id: "vehicle",
				accessor: (row: any) => row.vehicle
			},
			{
				Header: Strings.worksheets.number,
				id: "number",
				accessor: (row: any) => row.number
			},
			{
				Header: Strings.fields.date,
				id: "date",
				accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy")
			},
			{
				Header: Strings.fields.description,
				id: "description",
				accessor: (row: any) => row.notes
			},
			{
				Header: Strings.vehicles.kms,
				id: "km",
				align: "right",
				accessor: (row: any) => row.km?.toFixed(0) || '-'
			},
			{
				Header: Strings.worksheets.litersAdBlue,
				id: "litersAdBlue",
				align: "right",
				accessor: (row: any) => row.litersAdBlue?.toFixed(2) || '-'
			},
			{
				Header: Strings.worksheets.amountAdBlue,
				id: "valueAdBlue",
				align: "right",
				accessor: (row: any) => `${row.valueAdBlue?.toFixed(2) || '-'} €`
			},
			{
				Header: Strings.worksheets.liters,
				id: "liters",
				align: "right",
				accessor: (row: any) => row.liters?.toFixed(2) || '-'
			},
			{
				Header: Strings.fields.total,
				id: "value",
				align: "right",
				accessor: (row: any) => `${row.value?.toFixed(2) || '-'} €`
			}
		] as Array<any>;

		if (customer) columnsFuel.shift();
		
		const columnsWorkValues = [
			{
				Header: Strings.customers.customer,
				id: "customer",
				accessor: (row: any) => row.customer
			},
			{
				Header: Strings.vehicles.licensePlate,
				id: "vehicle",
				accessor: (row: any) => row.vehicle
			},
			{
				Header: Strings.worksheets.number,
				id: "number",
				accessor: (row: any) => row.number
			},
			{
				Header: Strings.fields.date,
				id: "date",
				accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy")
			},
			{
				Header: Strings.fields.description,
				id: "description",
				accessor: (row: any) => row.description
			},
			{
				Header: Strings.worksheets.customerNumber,
				id: "customerNumber",
				accessor: (row: any) => row.customerNumber
			},
			{
				Header: Strings.worksheets.vehicleNumber,
				id: "vehicleNumber",
				accessor: (row: any) => row.vehicleNumber
			},
			{
				Header: Strings.worksheets.workValueExtra,
				id: "valueExtra",
				align: "right",
				accessor: (row: any) => `${row.valueExtra?.toFixed(2) || '-'} €`
			},
			{
				Header: Strings.worksheets.workValue,
				id: "value",
				align: "right",
				accessor: (row: any) => `${row.value?.toFixed(2) || '-'} €`
			}
		] as Array<any>;

		if (customer) columnsWorkValues.shift();

		const columnsExtraWorks = [
			{
				Header: Strings.customers.customer,
				id: "customer",
				accessor: (row: any) => row.customer
			},
			{
				Header: Strings.worksheets.number,
				id: "number",
				accessor: (row: any) => row.number
			},
			{
				Header: Strings.fields.date,
				id: "date",
				accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy")
			},
			{
				Header: Strings.fields.description,
				id: "description",
				accessor: (row: any) => row.description
			},
			{
				Header: Strings.staff.staff,
				id: "staff",
				accessor: (row: any) => row.staff
			},
			{
				Header: Strings.fields.total,
				id: "value",
				align: "right",
				accessor: (row: any) => `${row.value?.toFixed(2) || '-'} €`
			}
		] as Array<any>;

		if (customer) columnsExtraWorks.shift();

		return (
			<React.Fragment>
				{this.renderPendingWorksheets()}
				<Row gutter={[24, 24]}>
					{Object.keys(cards).map((elem: any, index: number) => {
						const card = cards[elem];
						return (
							<Col key={`card_${index}`} xs={12} sm={6} md={6}>
								<ContentWrapper extraClass={`DashboardCard`}>
									<Card
										title={card.title}
										hoverable={true}
										extra={<Icon name={card.icon} className="DashboardCardIcon" />}
									>
										<p className="CardValue">{card.isMonetary ? `${card.value} €` : card.value}</p>
									</Card>
								</ContentWrapper>
							</Col>
						);
					})}
				</Row>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<div className="DashboardMainChart">
						<div className="DashboardMainChartTitle">
							<Icon name={"expenses"} />
							<span>{Strings.dashboard.workValues}</span>
						</div>
						<div className="Dashboard_Data_Charts">
							<div id="data1" className={"Dashboard_Generic_Chart$ __active"}>
								<Line {...config("workValuesStatistics")} />
							</div>
						</div>
					</div>
				</ContentWrapper>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<div className="DashboardMainChart">
						<div className="DashboardMainChartTitle">
							<Icon name={"expenses"} />
							<span>{Strings.dashboard.fuelDetails}</span>
						</div>
						<div className="Dashboard_Data_Charts">
							<div id="data1" className={"Dashboard_Generic_Chart$ __active"}>
								<Line {...config("fuelStatistics")} />
							</div>
						</div>
					</div>
				</ContentWrapper>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<div className="DashboardMainChart">
						<div className="DashboardMainChartTitle">
							<Icon name={"expenses"} />
							<span>{Strings.dashboard.costsDetails}</span>
						</div>
						<div className="Dashboard_Data_Charts">
							<div id="data1" className={"Dashboard_Generic_Chart$ __active"}>
								<Line {...config("costsStatistics")} />
							</div>
						</div>
					</div>
				</ContentWrapper>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<div className="DashboardMainChart">
						<div className="DashboardMainChartTitle">
							<Icon name={"truck"} />
							<span>{Strings.dashboard.kmDetails}</span>
						</div>
						<div className="Dashboard_Data_Charts">
							<div id="data1" className={"Dashboard_Generic_Chart$ __active"}>
								<Line {...config("kmStatistics")} />
							</div>
						</div>
					</div>
				</ContentWrapper>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<div className="DashboardMainChart">
						<div className="DashboardMainChartTitle">
							<Icon name={"speedometer"} />
							<span>{Strings.dashboard.hoursDetails}</span>
						</div>
						<div className="Dashboard_Data_Charts">
							<div id="data1" className={"Dashboard_Generic_Chart$ __active"}>
								<Line {...config("hoursStatistics")} />
							</div>
						</div>
					</div>
				</ContentWrapper>
				<ContentWrapper>
					<Table
						title={{
							icon: "expenses",
							title: Strings.dashboard.revenueAnalysis
						}}
						data={revenueAnalysis}
						columns={[
							{
								Header: Strings.customers.customer,
								id: "customer",
								accessor: (row: any) => row.customer
							},
							{
								Header: Strings.worksheets.workValueExtra,
								id: "valueExtra",
								align: "right",
								accessor: (row: any) => `${row.valueExtra?.toFixed(2) || '-'} €`
							},
							{
								Header: Strings.worksheets.workValue,
								id: "value",
								align: "right",
								accessor: (row: any) => `${row.value?.toFixed(2) || '-'} €`
							}
						]}
						fullPage
						isSinglePage
						paginated={false}
					/>
				</ContentWrapper>
				<ContentWrapper>
					<Table
						title={{
							icon: "expenses",
							title: Strings.dashboard.workValues
						}}
						data={workValuesResume}
						actions={{
							edit: (original: any) => ({
								onClick: () => {
									this.setState({ showValueModal: true, tempWorksheet: { ...original } })
								}
							}),
						}}
						columns={columnsWorkValues}
						fullPage
						paginated
					/>
				</ContentWrapper>
				<ContentWrapper>
					<Table
						title={{
							icon: "expenses",
							title: Strings.dashboard.costsResume
						}}
						data={costsResume}
						actions={{
							edit: (original: any) => ({
								onClick: () => {
									this.setState({ showValueModal: true, tempWorksheet: { ...original } })
								}
							}),
						}}
						columns={columnsCosts}
						fullPage
						paginated
					/>
				</ContentWrapper>
				<ContentWrapper>
					<Table
						title={{
							icon: "expenses",
							title: Strings.dashboard.fuelResume
						}}
						data={fuelResume}
						actions={{
							edit: (original: any) => ({
								onClick: () => {
									this.setState({ showValueModal: true, tempWorksheet: { ...original } })
								}
							}),
						}}
						columns={columnsFuel}
						fullPage
						paginated
					/>
				</ContentWrapper>
				<ContentWrapper>
					<Table
						title={{
							icon: "expenses",
							title: Strings.dashboard.extraWorksUse
						}}
						data={extraWorksResume}
						columns={columnsExtraWorks}
						isSinglePage
						fullPage
						paginated={false}
					/>
				</ContentWrapper>
			</React.Fragment>
		);
	}

	render() {
		const { user } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.dashboard}</title>
					<meta name="description" content="Description of Dashboard" />
				</Helmet>
				{user?.role === "driver" || user?.role === "helper" || user?.role === "mechanic" ? this.renderDriverDashboard() : this.renderAdminDashboard()}
				{this.renderValueModal()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	selectedBusiness: state.selectedBusiness,
	language: state.language,
	user: state.user
});

export default connect(mapStateToProps)(Dashboard);
