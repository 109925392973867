import React from "react";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Props } from "./types";
import { Helmet } from "react-helmet";
import Compressor from "compressorjs";
import { ContentWrapper, Icon, PhoneInput, Table } from "components";
import { Col, Input, notification, Row, Select, Drawer, Tabs, Switch, DatePicker } from "antd";
import Dropzone from "react-dropzone";
import { translate } from "utils/utils";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { DateTime } from "luxon";
import moment from "moment";
import "./styles.scss";

class StaffDetail extends React.Component<Props, any> {
	phoneValidationInput: any;
	constructor(props: Props) {
		super(props);

		this.state = {
			staff: null,
			name: null,
			email: null,
			phone: null,
			photo: null,
			hasUnsavedFields: false,
			isNew: props.match?.params?.id === "new",
			tab: "1"
		};

		this.onDrop = this.onDrop.bind(this);
	}

	componentDidMount() {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.staff,
							route: "/staff",
							icon: "working-briefcase"
						},
						{
							text: translate(this.state.staff?.name || "")
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () => this.submitStaff(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true
						}
					]
				};
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
		dispatch(setTitle(`${Strings.sidebar.staff} - `));
	}

	async getData() {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriStaff(match?.params?.id)
			});

			if (response.ok) {
				const { staff } = response.data.results || {};
				this.setState({ staff, ...staff });
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.staff,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});

			console.log("Error", err as string);
		}

		dispatch(setLoader(false));
	}

	async submitStaff() {
		const { role, name, photo } = this.state;
		const { dispatch, match } = this.props;

		if (!this.validStaff()) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			const body = new FormData();

			body.append("name", name);
			body.append("role", role);

			if (photo && Object.keys(photo).length > 0) {
				if (photo.file) {
					body.append("photo", photo.file);
				} else {
					body.append("photo", photo);
				}
			}

			response = await API.put({
				url: Endpoints.uriStaff(match?.params?.id),
				data: body
			});

			if (response.ok) {
				const { staff } = response.data.results;
				this.setState({
					staff,
					...staff,
					hasUnsavedFields: false
				});

				notification.success({
					message: Strings.sidebar.staff,
					description: Strings.staff.editedSuccessfully,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.staff,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.staff,
				description: response?.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitDocument() {
		const { tempDocument } = this.state;
		const { dispatch, match } = this.props;

		if (!tempDocument?.description?.trim() || !tempDocument?.file) {
			return notification.warn({
				message: Strings.staff.documents,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		const body = new FormData();
		body.append("description", tempDocument.description);
		body.append("document", tempDocument.file);

		try {
			const request = tempDocument._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriStaff(`${match?.params?.id}/document/${tempDocument._id || ""}`),
				data: body
			});

			if (response.ok && response.data.results?.staff) {
				this.setState({
					showDrawer: false,
					tempDocument: null,
					...response.data.results.staff
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeDocument(id: string) {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriStaff(`${match?.params?.id}/document/${id}`)
			});

			if (response.ok && response.data.results?.staff) {
				this.setState({
					...response.data.results.staff
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data?.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitCost() {
		const { tempCost } = this.state;
		const { dispatch, match } = this.props;

		const cost = {
			...tempCost,
			startDate: moment(tempCost?.startDate).utc().startOf("day").toISOString() || moment().startOf("day")
		}

		if (!cost?.startDate || !cost?.grossSalary || !cost?.socialSecuriyPercentage || !cost?.annualCost || !cost?.dailyCost) {
			return notification.warn({
				message: Strings.staff.documents,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		try {
			const request = cost._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriStaff(`${match?.params?.id}/cost/${cost._id || ""}`),
				data: { ...cost }
			});

			if (response.ok && response.data.results?.staff) {
				this.setState({
					showDrawer: false,
					tempCost: null,
					...response.data.results.staff
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeCost(id: string) {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriStaff(`${match?.params?.id}/cost/${id}`)
			});

			if (response.ok && response.data.results?.staff) {
				this.setState({
					...response.data.results.staff
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data?.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validStaff() {
		const { role, name } = this.state;

		if (!name) {
			notification.warn({
				message: Strings.sidebar.staff,
				description: Strings.staff.user,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!role) {
			notification.warn({
				message: Strings.sidebar.staff,
				description: Strings.staff.user,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState({
							photo: { file: result, preview: res },
							hasUnsavedFields: true
						});
					});
				}
			});
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	onDropFile = async (files: any) => {
		try {
			this.setState((state: any) => ({
				tempDocument: {
					...state.tempDocument,
					file: files[files.length - 1]
				}
			}));
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	};
	
	calculateCosts = async (cost: any) => {
		
		try {
			const grossSalary = cost?.grossSalary || 0;
			const socialSecuriyPercentage = cost?.socialSecuriyPercentage || 0;
			const extraSalary = cost?.extraSalary || 0;
			const mealAllowance = cost?.mealAllowance || 0;
			const companyCar = cost?.companyCar || 0;
			const healthInsurance = cost?.healthInsurance || 0;

			let annualCost = 0;
			let dailyCost = 0;

			annualCost = (grossSalary * 14 * (1 + (socialSecuriyPercentage / 100)) + (extraSalary * 11) + (mealAllowance * 11) + (companyCar * 12) + (healthInsurance * 12));
			dailyCost = annualCost / 220;

			this.setState((state: any) => ({
				tempCost: {
					...state.tempCost,
					...cost,
					annualCost: annualCost.toFixed(2),
					dailyCost: dailyCost.toFixed(2),
				}
			}));
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	};

	renderSidebarContent() {
		const { tempDocument, tempCost, drawerType } = this.state;

		if (drawerType === "documents") {
			return (
				<Row gutter={[0, 10]}>
					<Col xs={24}>
						<label htmlFor="document_descritpion" className="InputLabel --label-required">
							{Strings.fields.description}
						</label>
						<Input
							id="document_descritpion"
							placeholder={Strings.fields.description}
							value={tempDocument?.description || ""}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempDocument: { ...state.tempDocument, description: value }
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="document_file" className="InputLabel --label-required">
							{Strings.fields.file}
						</label>
						<Dropzone
							accept=".pdf, .png, .jpg, .jpeg"
							multiple={false}
							style={{ cursor: "pointer" }}
							className="ant-input"
							onDrop={(files) => this.onDropFile(files)}
						>
							<div className="FileUpload">
								<Icon
									name="document"
									style={{ marginRight: "10px", color: tempDocument?.file ? "#333" : "#bfbfbf" }}
								/>
								<span
									style={{
										color: tempDocument?.file ? "#333" : "#bfbfbf",
										fontSize: 12,
										wordBreak: "break-all"
									}}
								>
									{tempDocument?.file
										? typeof tempDocument.file === "string"
											? tempDocument.file
											: tempDocument.file.name
										: Strings.fields.clickToUpload}
								</span>
							</div>
						</Dropzone>
					</Col>
				</Row>
			);
		} else {
			return (
				<Row gutter={[0, 10]}>
					<Col xs={24}>
						<label htmlFor="startDate" className="InputLabel --label-required">
							{Strings.staff.startDate}
						</label>
						<DatePicker
							id="startDate"
							monthCellRender={(currentDate: any) => {
								return currentDate.format("MMM");
							}}
							allowClear={false}
							format="DD-MM-YYYY"
							style={{ width: "100%", height: 40 }}
							value={tempCost?.startDate ? moment(tempCost?.startDate).local() : moment().startOf("day")}
							onChange={(value: any) => {
								this.setState((state: any) => ({ tempCost: { ...state.tempCost, startDate: value }}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="grossSalary" className="InputLabel --label-required">
							{Strings.staff.grossSalary}
						</label>
						<Input
							id="grossSalary"
							placeholder={Strings.staff.grossSalary}
							type="number"
							addonAfter="€"
							value={tempCost?.grossSalary || ""}
							onChange={(event: any) => {
								const value = event.target.value;
								const cost = { ...tempCost, grossSalary: value }
								this.calculateCosts(cost);
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="socialSecuriyPercentage" className="InputLabel --label-required">
							{Strings.staff.socialSecuriyPercentage}
						</label>
						<Input
							id="socialSecuriyPercentage"
							placeholder={Strings.staff.socialSecuriyPercentage}
							type="number"
							addonAfter="%"
							value={tempCost?.socialSecuriyPercentage || ""}
							onChange={(event: any) => {
								const value = event.target.value;
								const cost = { ...tempCost, socialSecuriyPercentage: value }
								this.calculateCosts(cost);
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="extraSalary" className="InputLabel">
							{Strings.staff.extraSalary}
						</label>
						<Input
							id="extraSalary"
							placeholder={Strings.staff.extraSalary}
							type="number"
							addonAfter="€"
							value={tempCost?.extraSalary || ""}
							onChange={(event: any) => {
								const value = event.target.value;
								const cost = { ...tempCost, extraSalary: value }
								this.calculateCosts(cost);
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="mealAllowance" className="InputLabel">
							{Strings.staff.mealAllowance}
						</label>
						<Input
							id="mealAllowance"
							placeholder={Strings.staff.mealAllowance}
							type="number"
							addonAfter="€"
							value={tempCost?.mealAllowance || ""}
							onChange={(event: any) => {
								const value = event.target.value;
								const cost = { ...tempCost, mealAllowance: value }
								this.calculateCosts(cost);
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="companyCar" className="InputLabel">
							{Strings.staff.companyCar}
						</label>
						<Input
							id="companyCar"
							placeholder={Strings.staff.companyCar}
							type="number"
							addonAfter="€"
							value={tempCost?.companyCar || ""}
							onChange={(event: any) => {
								const value = event.target.value;
								const cost = { ...tempCost, companyCar: value }
								this.calculateCosts(cost);
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="healthInsurance" className="InputLabel">
							{Strings.staff.healthInsurance}
						</label>
						<Input
							id="healthInsurance"
							placeholder={Strings.staff.healthInsurance}
							type="number"
							addonAfter="€"
							value={tempCost?.healthInsurance || ""}
							onChange={(event: any) => {
								const value = event.target.value;
								const cost = { ...tempCost, healthInsurance: value }
								this.calculateCosts(cost);
							}}
						/>
					</Col>
					<Col xs={24}>
						<div
                            className={`General_ColorFul_Switch ${
                                tempCost?.overheads ? "__active" : ""
                            }`}
                        >
							<span>{Strings.staff.overheads}</span>
							<Switch
								className={`Switch ${
									tempCost?.overheads ? "__active" : ""
								}`}
								checked={(tempCost?.overheads) || false}
								size="small"
								onChange={(value: any) => {
									this.setState((state: any) => ({
										tempCost: { ...state.tempCost, overheads: value }
									}));
								}}
							/>
                        </div>
						
					</Col>
					<Col xs={24}>
						<label htmlFor="annualCost" className="InputLabel">
							{Strings.staff.annualCost}
						</label>
						<Input
							id="annualCost"
							placeholder={Strings.staff.annualCost}
							type="number"
							addonAfter="€"
							disabled
							value={tempCost?.annualCost || ""}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="dailyCost" className="InputLabel">
							{Strings.staff.dailyCost}
						</label>
						<Input
							id="dailyCost"
							placeholder={Strings.staff.dailyCost}
							type="number"
							addonAfter="€"
							disabled
							value={tempCost?.dailyCost || ""}
						/>
					</Col>
				</Row>
			);
		}
	}

	renderDrawer() {
		const { showDrawer, drawerType } = this.state;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name={drawerType === "documents" ? "working-briefcase" : "expenses"} />
						<p>{drawerType === "documents" ? Strings.staff.documents : Strings.staff.costs}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => {
								if (drawerType === "documents") {
									this.submitDocument();
								} else {
									this.submitCost();
								}
							}}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempDocument: null, tempCost: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={400}
				onClose={() => this.setState({ showDrawer: false, tempDocument: null, tempCost: null })}
				visible={showDrawer}
			>
				{this.renderSidebarContent()}
			</Drawer>
		);
	}

	renderDocuments() {
		const { documents = [] } = this.state;

		return (
			<Table
				title={{
					icon: "working-briefcase",
					title: Strings.staff.documents
				}}
				data={documents}
				columns={[
					{
						Header: Strings.fields.name,
						id: "description",
						accessor: (row: any) => row.description
					}
				]}
				filterable
				paginated={false}
				add={{
					onClick: () => this.setState({ drawerType: "documents", tempDocument: {}, showDrawer: true })
				}}
				actions={{
					edit: (original: any) => ({
						onClick: () => this.setState({ drawerType: "documents", tempDocument: { ...original }, showDrawer: true })
					}),
					view: (original: any) => ({
						onClick: () => window.open(original.file)
					}),
					remove: (original: any) => ({
						onClick: () => this.removeDocument(original._id)
					})
				}}
			/>
		);
	}

	renderCosts() {
		const { costs = [] } = this.state;

		return (
			<Table
				title={{
					icon: "expenses",
					title: Strings.staff.costs
				}}
				data={costs}
				columns={[
					{
						Header: Strings.staff.startDate,
						id: "startDate",
						accessor: (row: any) => row.startDate && DateTime.fromISO(row.startDate).toFormat("dd/MM/yyyy"),
					},
					{
						Header: Strings.staff.grossSalary,
						id: "grossSalary",
						accessor: (row: any) => `${row.grossSalary?.toFixed(2) || '-'} €`
					},
					{
						Header: Strings.staff.socialSecuriyPercentage,
						id: "socialSecuriyPercentage",
						accessor: (row: any) => `${row.socialSecuriyPercentage?.toFixed(2) || '-'} %`
					},
					{
						Header: Strings.staff.extraSalary,
						id: "extraSalary",
						accessor: (row: any) => `${row.extraSalary?.toFixed(2) || '-'} €`
					},
					{
						Header: Strings.staff.annualCost,
						id: "annualCost",
						accessor: (row: any) => `${row.annualCost?.toFixed(2) || '-'} €`
					},
					{
						Header: Strings.staff.dailyCost,
						id: "dailyCost",
						accessor: (row: any) => `${row.dailyCost?.toFixed(2) || '-'} €`
					},
				]}
				searchable={false}
				paginated={false}
				add={{
					onClick: () => this.setState({ showDrawer: true, drawerType: "costs", tempCost: {} })
				}}
				actions={{
					edit: (original: any) => ({
						onClick: () =>
							this.setState({ showDrawer: true, drawerType: "costs", tempCost: { ...original } })
					}),
					remove: (original: any) => ({
						onClick: () => this.removeCost(original._id)
					})
				}}
			/>
		);
	}

	render() {
		const { name, email, photo, phone, role, isNew, tab } = this.state;
		const cnt = "PT";

		return (
			<div className="UserDetailScreen">
				<Helmet>
					<title>{Strings.users.header}</title>
					<meta name="description" content="Edit your user\'s information" />
				</Helmet>
				<ContentWrapper extraClass="UserContainer">
					<div className="UserAvatarContainer">
						<Dropzone
							accept="image/jpg, image/jpeg, image/png"
							className="UserAvatarInnerContainer"
							onDrop={this.onDrop}
						>
							<div className="AvatarWrapper">
								{photo ? (
									<img src={photo?.preview || photo} alt="customer avatar" />
								) : (
									<div className="UserAvatarPlaceholder">
										<Icon name="frame" />
										<p>{Strings.generic.userImage}</p>
									</div>
								)}
							</div>
						</Dropzone>
					</div>
					<div className="UserInformationWrapper">
						<Row gutter={[20, 20]}>
							<Col xs={12}>
								<label htmlFor="name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="name"
									placeholder={Strings.fields.name}
									value={name || ""}
									onChange={(event: any) => {
										const value = event.target.value;
										this.setState({ name: value, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="email" className="InputLabel --label-required">
									{Strings.fields.email}
								</label>
								<Input
									id="email"
									type="email"
									disabled={true}
									placeholder={Strings.fields.email}
									value={email || ""}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="phone" className="InputLabel">
									{Strings.fields.phone}
								</label>
								{Boolean(cnt) && (
									<PhoneInput
										ref={(ref: any) => {
											this.phoneValidationInput = ref;
										}}
										defaultCountry={cnt && cnt.toLowerCase()}
										value={phone || ""}
										inputClass="input-phone"
										onChange={(event: any) => {
											const value = event.target.value;
											this.setState({ phone: value, hasUnsavedFields: true });
										}}
									/>
								)}
							</Col>
							<Col xs={12}>
								<label htmlFor="role" className="InputLabel --label-required">
									{Strings.profile.role}
								</label>
								<Select
									id="role"
									key={`role_${role}`}
									className="tagsSelector"
									style={{ width: "100%" }}
									placeholder={Strings.placeholders.role}
									showSearch
									filterOption={(input: any, option: any) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={role || null}
									onChange={(elem: any) => this.setState({ role: elem, hasUnsavedFields: true })}
								>
									<Select.Option value="admin">{Strings.staff.admin}</Select.Option>
									<Select.Option value="mechanic">{Strings.staff.mechanic}</Select.Option>
									<Select.Option value="driver">{Strings.staff.driver}</Select.Option>
									<Select.Option value="helper">{Strings.staff.helper}</Select.Option>
								</Select>
							</Col>
						</Row>
					</div>
				</ContentWrapper>
				{!isNew && (
					<ContentWrapper extraStyle={{ padding: 20 }}>
						<Tabs onChange={(key: string) => this.setState({ tab: key })} defaultActiveKey="1">
							<Tabs.TabPane tab={Strings.staff.documents} key="1" />
							<Tabs.TabPane tab={Strings.staff.costs} key="2" />
						</Tabs>
						{tab === "1" && this.renderDocuments()}
						{tab === "2" && this.renderCosts()}
					</ContentWrapper>
				)}
				{this.renderDrawer()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(StaffDetail);
