import React, { Component } from "react";
import { push } from "connected-react-router";
import { ContentWrapper, Icon, PhoneInput, Table } from "components";
import { Row, Col, Input, notification, Tabs, Drawer } from "antd";
import Compressor from "compressorjs";
import Dropzone from "react-dropzone";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import { emailIsValid } from "utils/utils";
import Strings from "utils/strings";
import "./styles.scss";

class CustomerDetail extends Component<any, any> {
	phoneValidationInput: any;

	constructor(props: any) {
		super(props);

		this.state = {
			customer: null,
			hasUnsavedFields: false,
			isNew: props.match?.params?.id === "new",
			tab: "1"
		};

		this.onDrop = this.onDrop.bind(this);
	}

	componentDidMount(): void {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.clients,
							route: "/customers",
							icon: "user-group"
						},
						{
							text: this.state.isNew ? Strings.customers.new : this.state.name || Strings.generic.loading
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () => this.submitCustomer(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true
						}
					]
				};
			})
		);

		this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;

		if (isNew) {
			return dispatch(setTitle(Strings.customers.new));
		}

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCustomers(match?.params?.id)
			});

			if (response.ok) {
				dispatch(setTitle(response.data?.results?.customer?.name || Strings.generic.loading));
				this.setState({ customers: response.data?.results?.customer, ...response.data?.results?.customer });
			}
		} catch (err) {
			console.log("API Error", err);
		}

		dispatch(setLoader(false));
	}

	async submitCustomer() {
		const { name, email, phone, city, locality, address, address2, postalCode, isNew, image } = this.state;
		const { dispatch, match } = this.props;

		if (!name?.trim() || !address?.trim() || !city?.trim()) {
			return notification.warn({
				message: Strings.customers.customer,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		if (!emailIsValid(email)) {
			return notification.warn({
				message: Strings.customers.customer,
				description: Strings.fields.invalidEmail,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		const body = new FormData();
		body.append("name", name);
		body.append("email", email);
		body.append("phone", phone || "");
		body.append("city", city);
		body.append("locality", locality || "");
		body.append("address", address);
		body.append("address2", address2 || "");
		body.append("postalCode", postalCode || "");

		if (image && Object.keys(image).length > 0) {
			if (image.file) {
				body.append("image", image.file);
			} else {
				body.append("image", image);
			}
		}

		try {
			const request = !isNew ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriCustomers((!isNew && match?.params?.id) || ""),
				data: body
			});

			if (response.ok && response.data.results?.customer) {
				if (isNew) {
					return dispatch(push("/customers"));
				}

				this.setState({
					hasUnsavedFields: false,
					customer: response.data.results.customer,
					showDrawer: false,
					tempEntry: null,
					...response.data.results.customer
				});

				dispatch(setTitle(response.data.results?.customer?.name));

				notification.success({
					message: Strings.customers.customer,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitDocument() {
		const { tempEntry } = this.state;
		const { dispatch, match } = this.props;

		if (!tempEntry?.description?.trim() || !tempEntry?.file) {
			return notification.warn({
				message: Strings.staff.documents,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		const body = new FormData();
		body.append("description", tempEntry.description);
		body.append("document", tempEntry.file);

		try {
			const request = tempEntry._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriCustomers(`${match?.params?.id}/document/${tempEntry._id || ""}`),
				data: body
			});

			if (response.ok && response.data.results?.customer) {
				this.setState({
					showDrawer: false,
					tempEntry: null,
					customer: response.data.results.customer,
					...response.data.results.customer
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitContact() {
		const { tempEntry } = this.state;
		const { dispatch, match } = this.props;

		if (!tempEntry?.name?.trim() || !emailIsValid(tempEntry?.email) || !tempEntry.role) {
			return notification.warn({
				message: Strings.customers.contacts,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		try {
			const request = tempEntry._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriCustomers(`${match?.params?.id}/contact/${tempEntry._id || ""}`),
				data: { ...tempEntry }
			});

			if (response.ok && response.data.results?.customer) {
				this.setState({
					showDrawer: false,
					tempEntry: null,
					customer: response.data.results.customer,
					...response.data.results.customer
				});

				notification.success({
					message: Strings.customers.contacts,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeContact(id: string) {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriCustomers(`${match?.params?.id}/contact/${id}`)
			});

			if (response.ok) {
				this.setState({ customers: response.data?.results?.customer, ...response.data?.results?.customer });

				notification.success({
					message: Strings.customers.contacts,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeDocument(id: string) {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriCustomers(`${match?.params?.id}/document/${id}`)
			});

			if (response.ok) {
				this.setState({ customers: response.data?.results?.customer, ...response.data?.results?.customer });

				notification.success({
					message: Strings.staff.documents,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState({
							image: { file: result, preview: res },
							hasUnsavedFields: true
						});
					});
				}
			});
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	onDropFile = async (files: any) => {
		try {
			this.setState((state: any) => ({
				tempEntry: {
					...state.tempEntry,
					file: files[files.length - 1]
				}
			}));
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	};

	getRole(role: string): string {
		if (role === "admin") return Strings.staff.admin;
		if (role === "mechanic") return Strings.staff.mechanic;
		if (role === "driver") return Strings.staff.driver;
		return role;
	}

	renderContacts() {
		const { users = [] } = this.state;

		return (
			<Table
				title={{
					icon: "user-group",
					title: Strings.customers.contacts
				}}
				data={users}
				columns={[
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => row.name
					},
					{
						Header: Strings.fields.email,
						id: "email",
						accessor: (row: any) => row.email
					},
					{
						Header: Strings.fields.phone,
						id: "phone",
						accessor: (row: any) => row.phone || "-"
					},
					{
						Header: Strings.profile.role,
						id: "role",
						accessor: (row: any) => row.role && this.getRole(row.role)
					}
				]}
				filterable
				paginated={false}
				add={{
					onClick: () => this.setState({ showDrawer: true, drawerType: "contacts", tempEntry: {} })
				}}
				actions={{
					edit: (original: any) => ({
						onClick: () =>
							this.setState({ showDrawer: true, drawerType: "contacts", tempEntry: { ...original } })
					}),
					remove: (original: any) => ({
						onClick: () => this.removeContact(original._id)
					})
				}}
			/>
		);
	}

	renderDocuments() {
		const { documents = [] } = this.state;

		return (
			<Table
				title={{
					icon: "working-briefcase",
					title: Strings.staff.documents
				}}
				data={documents}
				columns={[
					{
						Header: Strings.fields.name,
						id: "description",
						accessor: (row: any) => row.description
					}
				]}
				filterable
				paginated={false}
				add={{
					onClick: () => this.setState({ tempEntry: {}, showDrawer: true, drawerType: "documents" })
				}}
				actions={{
					edit: (original: any) => ({
						onClick: () => this.setState({ tempEntry: { ...original }, showDrawer: true })
					}),
					view: (original: any) => ({
						onClick: () => window.open(original.file)
					}),
					remove: (original: any) => ({
						onClick: () => this.removeDocument(original._id)
					})
				}}
			/>
		);
	}

	renderDrawer() {
		const { showDrawer, drawerType } = this.state;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name={drawerType === "contacts" ? "user-group" : "working-briefcase"} />
						<p>{drawerType === "contacts" ? Strings.customers.contacts : Strings.staff.documents}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => {
								if (drawerType === "contacts") {
									this.submitContact();
								} else {
									this.submitDocument();
								}
							}}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ tempEntry: null, showDrawer: false })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={400}
				onClose={() => this.setState({ tempEntry: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { drawerType, tempEntry } = this.state;

		if (drawerType === "contacts") {
			return (
				<Row gutter={[0, 10]}>
					<Col xs={24}>
						<label htmlFor="contact_name" className="InputLabel --label-required">
							{Strings.fields.name}
						</label>
						<Input
							id="contact_name"
							value={tempEntry?.name || ""}
							placeholder={Strings.fields.name}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempEntry: { ...state.tempEntry, name: value }
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="contact_email" className="InputLabel --label-required">
							{Strings.fields.email}
						</label>
						<Input
							id="contact_email"
							value={tempEntry?.email || ""}
							placeholder={Strings.fields.email}
							type="email"
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempEntry: { ...state.tempEntry, email: value }
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="phone" className="InputLabel">
							{Strings.fields.phone}
						</label>
						<PhoneInput
							ref={(ref: any) => {
								this.phoneValidationInput = ref;
							}}
							defaultCountry="pt"
							value={tempEntry?.phone || ""}
							inputClass="input-phone"
							onChange={(event: any) => {
								this.setState((state: any) => ({
									tempEntry: { ...state.tempEntry, phone: event }
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="role" className="InputLabel --label-required">
							{Strings.placeholders.role}
						</label>
						<Input
							id="role"
							value={tempEntry?.role || ""}
							placeholder={Strings.placeholders.role}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempEntry: { ...state.tempEntry, role: value }
								}));
							}}
						/>
					</Col>
				</Row>
			);
		}

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<label htmlFor="document_descritpion" className="InputLabel --label-required">
						{Strings.fields.description}
					</label>
					<Input
						id="document_descritpion"
						value={tempEntry?.description || ""}
						placeholder={Strings.fields.description}
						onChange={(event: any) => {
							const value = event.target.value;
							this.setState((state: any) => ({
								tempEntry: { ...state.tempEntry, description: value }
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="document_file" className="InputLabel --label-required">
						{Strings.fields.file}
					</label>
					<Dropzone
						accept=".pdf, .png, .jpg, .jpeg"
						multiple={false}
						style={{ cursor: "pointer" }}
						className="ant-input"
						onDrop={(files) => this.onDropFile(files)}
					>
						<div className="FileUpload">
							<Icon
								name="document"
								style={{ marginRight: "10px", color: tempEntry?.file ? "#333" : "#bfbfbf" }}
							/>
							<span
								style={{
									color: tempEntry?.file ? "#333" : "#bfbfbf",
									fontSize: 12,
									wordBreak: "break-all"
								}}
							>
								{tempEntry?.file
									? typeof tempEntry.file === "string"
										? tempEntry.file
										: tempEntry.file.name
									: Strings.fields.clickToUpload}
							</span>
						</div>
					</Dropzone>
				</Col>
			</Row>
		);
	}

	render(): JSX.Element {
		const { isNew, name, image, email, phone, city, address, address2, tab } = this.state;
		const cnt = "PT";

		return (
			<React.Fragment>
				<Helmet>
					<title>
						{Strings.customers.customer} - {isNew ? Strings.customers.new : name || Strings.generic.loading}
					</title>
					<meta name="description" content="Description of Customer" />
				</Helmet>
				<div className="CustomerScreen">
					<ContentWrapper extraClass="CustomerContainer">
						<div className="CustomerAvatarContainer">
							<Dropzone
								accept="image/jpg, image/jpeg, image/png"
								className="CustomerAvatarInnerContainer"
								onDrop={this.onDrop}
							>
								<div className="AvatarWrapper">
									{image ? (
										<img src={image?.preview || image} alt="psychologist avatar" />
									) : (
										<div className="CustomerAvatarPlaceholder">
											<Icon name="frame" />
											<p>{Strings.generic.userImage}</p>
										</div>
									)}
								</div>
							</Dropzone>
						</div>
						<div className="CustomerInformationWrapper">
							<Row gutter={[20, 10]}>
								<Col xs={12}>
									<label htmlFor="name" className="InputLabel --label-required">
										{Strings.fields.name}
									</label>
									<Input
										id="name"
										placeholder={Strings.fields.name}
										value={name || ""}
										onChange={(event: any) => {
											this.setState({ name: event.target.value, hasUnsavedFields: true });
										}}
									/>
								</Col>
								<Col xs={12}>
									<label htmlFor="email" className="InputLabel --label-required">
										{Strings.fields.email}
									</label>
									<Input
										id="email"
										type="email"
										placeholder={Strings.fields.email}
										value={email || ""}
										onChange={(event: any) => {
											this.setState({ email: event.target.value, hasUnsavedFields: true });
										}}
									/>
								</Col>
								<Col xs={12}>
									<label htmlFor="phone" className="InputLabel">
										{Strings.fields.phone}
									</label>
									{Boolean(cnt) && (
										<PhoneInput
											ref={(ref: any) => {
												this.phoneValidationInput = ref;
											}}
											defaultCountry={cnt && cnt.toLowerCase()}
											value={phone || ""}
											inputClass="input-phone"
											onChange={(event: any) => {
												this.setState({ phone: event, hasUnsavedFields: true });
											}}
										/>
									)}
								</Col>
								<Col xs={12}>
									<label htmlFor="city" className="InputLabel --label-required">
										{Strings.fields.city}
									</label>
									<Input
										id="city"
										placeholder={Strings.fields.city}
										value={city || ""}
										onChange={(event: any) => {
											this.setState({ city: event.target.value, hasUnsavedFields: true });
										}}
									/>
								</Col>
								<Col xs={12}>
									<label htmlFor="address" className="InputLabel --label-required">
										{Strings.fields.address}
									</label>
									<Input
										id="address"
										placeholder={Strings.fields.address}
										value={address || ""}
										onChange={(event: any) => {
											this.setState({ address: event.target.value, hasUnsavedFields: true });
										}}
									/>
								</Col>
								<Col xs={12}>
									<label htmlFor="address2" className="InputLabel">
										{Strings.fields.address2}
									</label>
									<Input
										id="address2"
										placeholder={Strings.fields.address2}
										value={address2 || ""}
										onChange={(event: any) => {
											this.setState({ address2: event.target.value, hasUnsavedFields: true });
										}}
									/>
								</Col>
							</Row>
						</div>
					</ContentWrapper>
					{!isNew && (
						<ContentWrapper extraStyle={{ padding: 20 }}>
							<Tabs onChange={(key: string) => this.setState({ tab: key })} defaultActiveKey="1">
								<Tabs.TabPane tab={Strings.customers.contacts} key="1" />
								<Tabs.TabPane tab={Strings.staff.documents} key="2" />
							</Tabs>
							{tab === "1" && this.renderContacts()}
							{tab === "2" && this.renderDocuments()}
						</ContentWrapper>
					)}
				</div>
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(CustomerDetail);
