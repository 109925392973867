import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
	/* cSpell:enable */
	/* cSpell.language:en */
	en: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: "Edit",
			done: "Done",
			accept: "Accept",
			cancel: "Cancel",
			remove: "Remove",
			forget: "Forget User",
			delete: "Delete",
			success: "Success",
			active: "Active",
			expand: "Expand",
			toggle: "Toggle",
			save: "Save",
			yes: "Yes",
			no: "No",
			apply: "Apply",
			item: "Item",
			items: "Items",
			submit: "Submit",
			continue: "Continue",
			confirm: "Confirm",
			finish: "Finish",
			back: "Back",
			oops: "Oops",
			error: "Error",
			retry: "Retry",
			reset: "Reset",
			search: "Search",
			noResults: "No Results",
			clickHereTo: "Click here to {0}",
			requiredField: "{0} is a required field",
			duplicateEmail: "This email is already taken. Please use another email.",
			emptyList: "No {0} in List",
			leavePage: "Leave Page?",
			leave: "Leave",
			stay: "Stay",
			open: "Open",
			close: "Close",
			change: "Change",
			free: "Free",
			send: "Send",
			selected: "Selected",
			selecteds: "Selected",
			swalEditTitle: "Cancel Edit?",
			swalEditDescription: "Cancelling edit will erase all the unsaved information!",
			leaveDescription: "Leaving this page will erase all the unsaved information!",
			swalDeleteTitle: "Delete {0}?",
			swalDeleteDescription: "By leaving this page, you'll erase all the {0} on your list",
			genericSelect: "Select {0}",
			select: "Select",
			addGeneric: "Add {0}",
			moreOptions: "More Options",
			all: "All",
			enable: "Enable",
			newF: "New",
			newM: "New",
			addNewF: "Add new {0}",
			addNewM: "Add new {0}",
			default: "Default",
			editGeneric: "Edit {0}",
			infinite: "Infinite",
			details: "Details",
			noItemsSelected: "No Items Selected",
			clearList: "Clear List",
			loading: "Loading...",
			view: "View",
			actions: "Actions",
			invalidUrl: "Invalid URL. Please remove HTTP prefix.",
			shop: "Shop",
			noEntries: "No Entries",
			chatWalktroughText1:
				"We've added several FAQs that will help you know and understand how the Shop2Grow product works. These FAQs are available from the chat icon at the bottom right of the screen.",
			chatWalktroughText2:
				"We are also available through chat to help you the best way on to setting up your store.",
			skip: "Skip",
			walkTrough: "walkthrough",
			clone: "Clone",
			download: "Download",
			upload: "Upload",
			template: "Download Template",
			export: "Export",
			import: "Import",
			defaultItem: "an item",
			defaultItemRecipient: "this {0}",
			demo: "Demo",
			previewOnly: "Preview Only",
			selectPaymentMethod: "Please select a payment method before updating your subscription.",
			basic: "Basic",
			advanced: "Advanced",
			editMode: "Edit Mode",
			print: "Print",
			code: "Code",
			filters: " Filters",
			changeImage: "Click here to add an image file or drag it into this box.",
			userImage: `This user don't have any image`,
			changeVideo: "Click here to add a video file or drag it into this box.",
			changeYoutubeVideo: "Insert youtube link",
			changeAudio: "Click here to add an audio file or drag it into this box.",
			everyF: "All",
			uploadImages: "Drop images here or click to select files to upload",
			uploadVideos: "Drop animations here or click to select files to upload",
			none: "None",
			archiveMessage: "Are you sure you want to archive this?",
			archive: "Archive",
		},

		fields: {
			name: "Name",
			firstName: "First Name",
			lastName: "Last Name",
			centername: "Center Name",
			address: "Address",
			address2: "Address 2",
			addresses: "Addresses",
			country: "Country",
			state: "State",
			city: "City",
			zipCode: "PostCode",
			description: "Description",
			phone: "Phone",
			phoneNumber: "Phone Number",
			phoneShort: "Tel.:",
			mobilePhone: "Mobile Number",
			email: "Email",
			seoUrl: "SEO Url",
			tags: "Tags",
			ccNumber: "CC Number",
			billingAddress: "Billing Address",
			shippingAddress: "Shipping Address",
			vat: "VAT",
			password: "Password",
			message: "Message",
			image: "Image",
			images: "Images",
			imgPlaceholder: "Try dropping some images here, or click to select files to upload",
			filesPlaceholder: "Try dropping your file here, or click to select a file",
			subTotal: "Subtotal",
			total: "Total",
			quantity: "Quantity",
			ordered: "Ordered",
			totalQuantity: "Total Quantity",
			discount: "Discount",
			registerDate: "Register Date",
			invalidEmail: "Email is not Valid",
			tax: "Tax",
			addedOn: "Added On",
			startDate: "Start Date",
			endDate: "End Date",
			startHour: "Start Hour",
			endHour: "End Hour",
			filename: "Filename",
			openFile: "Open File",
			gender: "Gender",
			waitingForResults: "Waiting for Name or SKU...",
			link: "Link",
			date: "Date",
			otherRegions: "Other Zones",
			taxValues: "Tax Values",
			previewLink: "Preview Link",
			clickToUpload: "Click to Upload",
			photo: "Photo",
			autor: "Author",
			category: "Category",
			number: "Number",
			notes: "Notes / Observations",

			// Select
			selectCountry: "Select Country",
			selectState: "Select State",
			selectFilters: "Select Filters",
			selectDiscount: "Select Discount",

			countries: "Countries",
			states: "States",

			// Pairs
			valuePair: "{0} / {1}",
			placeHolderPair: "{0} => {1}",

			code: "Abbreviation",

			logo: "Logo",
			gif: "GIF",
			file: "File"
		},

		language: {
			header: "Language",
			defaultLanguage: "Default Language",
			languageNotActive: "Save the changes before changing the default language.",
			portuguese: "Portuguese",
			english: "English",
			german: "German",
			french: "French",
			spanish: "Spanish"
		},

		sidebar: {
			dashboard: "Dashboard",
			profile: "Profile",
			information: "Information",
			staff: "Staff",
			settings: "Settings",
			logs: "Logs",
			users: "Users",
			notifications: "Notifications",
			clients: "Customers",
			vehicles: "Vehicles"
		},

		header: {
			daysLeft: "days left on trial",
			upgrade: "Upgrade",
			account: "Account",
			maintenance: "Maintenance",
			visitStore: "Visit Store",
			deleteSuccess: "Dummy data deleted successfully",
			extend: "Extend Trial"
		},

		// Screens
		authentication: {
			welcome: "Welcome",
			header: "Authentication",
			register: "Register",
			login: "Login",
			logout: "Logout",
			signIn: "Sign in",
			confirmAccount: "Confirm Account",
			name: "Name",
			namePlaceholder: "Your name",
			email: "Email",
			emailPlaceholder: "Your email",
			password: "Password",
			passwordPlaceholder: "Your password",
			newPassword: "New password",
			newPasswordPlaceholder: "Enter new password",
			confirmPassword: "Confirm Password",
			confirmPasswordPlaceholder: "Repeat your password",
			forgotPassword: "Forgot your password? Click here.",
			loginFooter: "No account? Register here",
			registerFooter: "Do you have an account? Login here",
			resendCode1: "Have you not received your confirmation code? ",
			resendCode2: "Resend activation code",
			sendEmail: "Send Email",
			recoverPassword: "Recover Password",
			changePassword: "Change Password",
			changeEmail: "Change Email",
			newEmail: "New Email",
			repeatEmail: "Repeat Email",
			newEmailConfirm: "Confirm New Email",
			currentEmail: "Current Email",
			currentPassword: "Current Password",
			emailSent: "Email Sent Successfully",
			repeatPassword: "Repeat Password",
			rememberedPassword: "Did you remember your password?",
			adminPassword: "Admin Password",
			notLoggedIn: "You need to be logged in to access this page",
			noMatchPassword: "Passwords don't match",
			acceptSubtitle: "Please input a password to confirm your account.",
			confirmInvite: "Please type your password to accept invite.",
			goBackToLogin: "Go to the login screen",
			resendInviteEmail: "Resend Invitation Email",
			confirmResendInviteEmail: "Resend an invitation email to {0}?",
			forgetTitle:
				"Are you sure you want to block the user {0}? This action will leave the user without access to the app.",
			forgetConfirm: "Block User",
			enterCode: "Please enter the authy code"
		},

		acceptInvite: {
			header: "Accept Invite",
			title: "Hi {0}, {1} has invited you to belong {2}",
			title2: "Hi {0}, {1} has invited you to manage {2}",
			subTitle: "Please choose a password to confirm your account",
			expired: "This invitation link has expired"
		},

		notFound: {
			header: "Not Found"
		},

		importModal: {
			fileName: "Filename",
			size: "Size",
			import: "Import"
		},

		errors: {
			invalidArgs: "Invalid or missing arguments",
			invalidFields: "Invalid or missing fields",
			emailIsInvalid: "Email format is not valid",
			fillEmail: "Please input your email",
			fillPassword: "Please input your password",
			pleaseFillFormCorrectly: "Please fill the form correctly",
			fillField: "This field is mandatory",
			notSupportedFile: "File type not supported",
			nameIsEmpty: "Please fill name field",
			passwordsDontMatch: "Fields 'New password' and 'Confirm password' don't match",
			emailIsEmpty: "Please fill email field",
			fileTooBigorInvalid: "File size is larger than 8MB or file is corrupt",
			unsupportedFile: "File not supported",
			fileNotSupported: "File you tried to upload is not supported.",
			processingError: "Processing Error",
			imageProcessingError: "{images} could not be processed.",
			tempIsInvalid: "Temperature format is not valid",
			fillTemperature: "Please input your temperature",
			fahrTemperature: "Temperature should be between 32 and 113",
			celsTemperature: "Temperature should be between 0 and 45",
			fillValuesNumbers: "Please fill all values with numbers.",
			minMaxDifferent: "Minimum and Maximum should be different.",
			maxHigherThanMin: "Minimum should be lower than Maximum.",
			duplicateAIValue: "Duplicate Values for AI found."
		},

		profile: {
			profilePicture: "Profile Picture",
			role: "Role",
			editedSuccessfully: "Profile edited successfully",
			recurrence: "Recurrence",
			recurrenceActive: "Enable Recurrence",
			recurrenceUntil: "Recurrent until",
			recurringEndMissing: "Recurrence end date is mandatory.",
			recurrenceSuccess: "Recurrence settings saved successfully.",
			deleteRecurrence: "Delete Recurrence",
			noThankYou: "No Thank You",
			recurrenceTooltip: "Create recurrence",
			deleteRecurrenceDescription: "Do you want to delete recurrence on {date}?"
		},

		placeholders: {
			name: "Your name",
			email: "your@email.com",
			phoneNumber: "+351265000000",
			language: "Select a language option",
			role: "Select a role",
			currentPassword: "actual password",
			newPassword: "new password",
			confirmPassword: "new password",
			newEmail: "new@email.com",
			page: "page",
			titlePage: "Page Title",
			link: "https://www.google.com/"
		},

		staff: {
			admin: "Administrator",
			mechanic: "Mechanic",
			driver: "Driver",
			helper: "Helper",
			helpers: "Helper(s)",
			helper2: "Helper 2",
			helper3: "Helper 3",
			type: "Role",
			staff: "Staff",
			editedSuccessfully: "Staff edited successfully",
			passwordChangedSuccessfully: "Password changed successfully",
			emailChangedSuccessfully: "Email changed successfully",
			wrongPassword: "Wrong password",
			emailInUse: "Email in use",
			status: "Status",
			confirmed: "Confirmed",
			pending: "Pending",
			createStaff: "Create Staff",
			createdSuccessfully: "Staff member has been created successfully.",
			sysAdmin: "System Admin",
			addStaff: "Add a Staff Member",
			editStaff: "Edit Staff Member",
			addOwner: "Add an Owner",
			editOwner: "Edit Owner",
			user: "User",
			removeStaff: "Remove Staff",
			removed: "User has been removed as Staff sucessfully.",
			disableStaff: "Disable Staff Member",
			enableStaff: "Enable Staff Member",
			disabled: "Staff member disabled sucessfully.",
			enabled: "Staff member enabled sucessfully.",
			addNew: "Add new Staff",
			documents: "Documents",
			selectStaff: "Select Staff",
			costs: "Salary Costs",
			startDate: "Start Date",
			grossSalary: "Gross Salary",
			socialSecuriyPercentage: "Social Securiy %",
			extraSalary: "Extra Salary",
			mealAllowance: "Meal Allowance",
			companyCar: "Company Car",
			healthInsurance: "Health Insurance",
			overheads: "Overheads",
			annualCost: "Annual Cost",
			dailyCost: "Daily Cost",
		},

		ranges: {
			today: "Today",
			thisMonth: "This month",
			lastMonth: "Last month",
			thisYear: "This year",
			lastYear: "Last year"
		},

		dashboard: {
			period: "Period",
			active: "Active",
			homepage: "Homepage",
			inStock: "In Stock",
			daily: "Dialy",
			monthly: "Monthly",
			kmUse: "Total Km",
			kmDetails: "Km by Date",
			hoursUse: "Total Hours",
			hoursDetails: "Hours by Date",
			fuelUse: "Value Fuel",
			fuelLiters: "Liters Fuel",
			fuelDetails: "Fuel by Date",
			costsUse: "Total Costs",
			workValues: "Work Values",
			revenueAnalysis: "Total Work Value",
			extraWorksUse: "Total Extra Works",
			myFinishedWorks: "My Finished Works",
			costsDetails: "Costs by Date",
			costsResume: "Costs Resume",
			fuelResume: "Refuel Resume",
			selectDriver: "Driver",
			selectVehicle: "Vehicle",
			selectVehicleNumber: "Vehicle Nº",
			selectCustomer: "Customer"
		},

		settings: {
			pages: "Pages",
			emailTemplates: "E-mail Settings",
			pagesSubTitle:
				"In this area you will be able to change the content of pages, like Terms & Condition and Privacy Policy",
			emailsSubTitle:
				"Setup email templated, used to sent emails to users, and also used to manage staff invitation",
			appSettings: "App Settings",
			appSettingsTitle: "Change the general settings of your app",
			appConfigurations: "App Configurations",
			primaryColor: "Primary Color",
			gradientStart: "Gradient Color Start",
			gradientEnd: "Gradient Color End",
			pickColor: "Click to pick a color",
			imageColor: "Image Color",
			imageWhite: "Image White",
			splashImage: "Image to use on Spashscreen",
			register: "Allow User Registration",
			socialLogin: "Use Social Login",
			sentry: "App Sentry Link",
			storeLinkAndroid: "Android Store Link",
			storeLinkIos: "iOS Store Link",
			warningVersionsAndroid: "Android Warning Version",
			criticalVersionsAndroid: "Android Critical Version",
			warningVersionsIos: "iOS Warning Version",
			criticalVersionsIos: "iOS Critical Version",
			borderRadius: "Border Radius",
			expensesTypes: "Expenses Types",
			expensesTypesTitle: "Define expenses categories",
			selectPdf: "Select PDF",
		},

		pages: {
			single: "Page",
			title: "Title",
			content: "Content",
			new: "New Page",
			isLink: "Redirect"
		},

		templates: {
			single: "Template"
		},

		emails: {
			single: "E-mail",
			tags: "Tags",
			preview: "Preview",
			subject: "Subject",
			cc: "CC",
			templates: "E-mail Templates",
			invite: "Staff Invite",
			edited: "E-mail template saved successfully."
		},

		serverErrors: {
			title: "Internal Server Error",
			subTitle: "It's not you, it's us!",
			refresh: "Go Back to Previous Page",
			backToDashboard: "Go to Dashboard",
			wentWrong: "Something went wrong."
		},

		logs: {
			dateRange: "Date Range",
			userId: "User Id",
			url: "Url",
			token: "Token",
			codes: "Codes",
			methods: "Methods",
			sources: "Sources",
			types: "Types"
		},

		emailTemplates: {
			generalEmail: "Generic E-mail",
			accountConfirmationCode: "User Account Confirmation by Code",
			recoverPasswordCode: "User Password Recovery by Code",
			accountConfirmationLink: "User Account Confirmation by Link",
			recoverPasswordLink: "User Password Recovery by Link",
			reservationEmail: "User Reservation E-mail",
			staffAccountConfirmation: "Staff Account Confirmation for BO",
			staffRecoverPassword: "Staff Recover Password for BO",
			supportEmail: "Support E-mail",
			resetPassword: "Reset Password (BO)",
			generalCleanWithoutButton: "General Clean Without Button",
			generalCleanWithButton: "General Clean With Button",
			generalWithCode: "General With Code",
			confirmAccount: "Confirm Account (BO)",
			welcome: "Welcome E-mail",
			generalWithLink: "General With Link"
		},

		notifications: {
			new: "New Notification",
			title: "Notification Title",
			message: "Notification Text",
			email: "Email Content",
			date: "Notification Scheduled Date",
			sendEmail: "Send E-mail",
			created: "Notification has been created successfully.",
			edited: "Notification has been edited successfully.",
			deleted: "Notification has been deleted successfully.",
			missingTitle: "Title is a mandatory field.",
			missingDescription: "Description is a mandatory field.",
			missingDate: "Scheduled Date is a mandatory field.",
			missingEmailContent: "Email content is a mandatory field.",
			notificationText: "Notification Text"
		},

		users: {
			header: "User",
			fullInfo: "User's Information",
			addUser: "Add User",
			editUser: "Edit User",
			title: "Users",
			kickTitle: "Blocked Users",
			solveKick: "Kick solved",
			session: "Session",
			reason: "Reason"
		},

		customers: {
			new: "New customer",
			customer: "Customer",
			noLogo: "This customer has no logo yet",
			contacts: "Contacts"
		},

		vehicles: {
			new: "New vehicle",
			vehicle: "Vehicle",
			licensePlate: "License Plate",
			type: "Type",
			truck: "Truck",
			van: "Van",
			car: "Car",
			service: "Service",
			services: "Services",
			kms: "Vehicle Kms",
			viaVerde: "Via Verde",
			uploadViaVerde: "Upload Via Verde",
			uploadFile: "Click or drag the Via Verde file into this box.",
			avarage: "Avarage",
		},

		worksheets: {
			worksheet: "Worksheet",
			worksheets: "Worksheets",
			generalInfo: "General Info",
			new: "New worksheet",
			number: "Work Number",
			exitHour: "Time of departure",
			returnHour: "Time of arrival",
			exitRegistration: "Exit registration",
			arrivalRegistration: "Arrival registration",
			createWorksheet: "Create worksheet",
			finishWorksheet: "Finish worksheet",
			validation: "In Validation",
			validate: 'Validate',
			validateAll: 'Validate All',
			pending: "Pending",
			started: "Started",
			finished: "Finished",
			invalidHour: "The return hour is lower than the starting hour, please fix the return hour.",
			invalidKm: "The number of km is lower than the number of km of the last service made by this vehicle.",
			invalidRefuelKm: "The number of km is lower than the number of km when this service started.",
			fillFields: "Please fill all required fields.",
			costs: "Expenditure",
			refuels: "Refuels",
			amount: "Amount",
			amountAdBlue: "Amount AdBlue",
			notes: "Notes",
			costType: "Cost type",
			status: "Status",
			customerNumber: "Route Nº",
			origin: "Origin",
			destination: "Destination",
			route: "Route",
			withoutVehicle: "Work without vehicle",
			vehicleNumber: "Vehicle Nº",
			vehiclesNumber: "Car Nº",
			internalWork: "Internal Work",
			workValue: "Work Value",
			workValueExtra: "Work Value Extra",
			extraWork: "Extra Work (Staff)",
			extraWorkValue: "Extra Value (Staff)",
			workValueExtraShort: "Extra",
			extraWorkValueShort: "Extra (Staff)",
			extraWorkValueShortNew: "Extra New (Staff)",
			totalKm: "Mileage travalled",
			impossibleMileage: "Mileage travelled is not valid.",
			pendingWorksheets: "Pending worksheets",
			notDefined: "Not defined yet",
			noneAvailable: "You have no pending worksheets.",
			exitKm: "Start Km",
			returnKm: "End Km",
			liters: "Liters",
			litersAdBlue: "Liters AdBlue",
			dailyView: "Daily View",
			sendValidation: "Change worksheet from in validation to pending? {0}",
			sendValidationAll: "Change all worksheets from in validation to pending? {0}",
			toll: "Tolls",
			entry: "Entry",
			exit: "Exit",
			staffWithoutWork: "Staff without Allocation",
			cloneDate: "Select the date that the worksheets will be cloned",
		}
	},
	/* cSpell.language:pt,pt_PT */
	pt: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: "Editar",
			done: "Concluir",
			accept: "Aceitar",
			cancel: "Cancelar",
			remove: "Remover",
			forget: "Esquecer User",
			delete: "Eliminar",
			success: "Sucesso",
			active: "Ativar",
			expand: "Expandir",
			toggle: "Toggle",
			save: "Guardar",
			yes: "Sim",
			no: "Não",
			apply: "Aplicar",
			item: "Artigo",
			items: "Artigos",
			submit: "Submeter",
			continue: "Continuar",
			confirm: "Confirmar",
			finish: "Terminar",
			back: "Voltar",
			oops: "Oops",
			error: "Erro",
			retry: "Tentar novamente",
			reset: "Reset",
			search: "Pesquisar",
			noResults: "Sem Resultados",
			clickHereTo: "Clique para {0}",
			requiredField: "{0} é um campo obrigatório",
			duplicateEmail: "Este email já está a ser utilizado. Por favor utilize outro email.",
			emptyList: "Sem {0} na lista",
			leavePage: "Sair da Página?",
			leave: "Sair",
			stay: "Ficar",
			open: "Abrir",
			close: "Fechar",
			change: "Mudar",
			free: "Grátis",
			send: "Enviar",
			selected: "Selecionado",
			selecteds: "Selecionados",
			swalEditTitle: "Cancelar Alteração?",
			swalEditDescription: "Cancelar edição vai apagar toda a informação não guardada",
			leaveDescription: "Ao sair desta página vai apagar toda a informação não guardada",
			swalDeleteTitle: "Apagar {0}?",
			swalDeleteDescription: "Ao sair desta página vai apagar todos os {0} na sua lista",
			genericSelect: "Selecionar {0}",
			select: "Selecionar",
			addGeneric: "Adicionar {0}",
			moreOptions: "Mais Opções",
			all: "Tudo",
			enable: "Ativar",
			newF: "Nova",
			newM: "Novo",
			addNewF: "Adicionar nova {0}",
			addNewM: "Adicionar novo {0}",
			default: "Pré-Definido",
			editGeneric: "Editar {0}",
			infinite: "Infinito",
			details: "Detalhes",
			noItemsSelected: "Nenhum Item Selecionado",
			clearList: "Limpar lista",
			loading: "A Carregar...",
			view: "Ver",
			actions: "Ações",
			invalidUrl: "URL inválido. Por favor remova o prefixo HTTP.",
			shop: "Loja",
			noEntries: "Sem Valores",
			chatWalktroughText1:
				"Adicionámos diversas FAQ que podem ajudar a conhecer e entender como funciona o produto Shop2Grow. Essas FAQ estão disponíveis no ícone de chat no canto inferior direito do ecrã.",
			chatWalktroughText2:
				"Estamos também disponíveis, através de chat, para ajudar na melhor maneira de criar e definir a sua loja.",
			skip: "Saltar Walkthrough",
			walkTrough: "Walkthrough",
			clone: "Clonar",
			download: "Download",
			upload: "Upload",
			template: "Download Template",
			export: "Exportar",
			import: "Importar",
			defaultItem: "um item",
			defaultItemRecipient: "este/esta {0}",
			demo: "Demo",
			previewOnly: "Apenas Preview",
			selectPaymentMethod: "Por favor, selecione um método de pagamento antes de atualizar a sua subscrição.",
			basic: "Básico",
			advanced: "Avançado",
			editMode: "Modo de Edição",
			print: "Imprimir",
			code: "Codigo",
			filters: "Filtros",
			changeImage: "Clique para adicionar uma imagem ou arraste o ficheiro para esta caixa.",
			userImage: `Este utilizador ainda não tem imagem`,
			changeVideo: "Clique para adicionar um ficheiro de video ou arraste o ficheiro para esta caixa.",
			changeYoutubeVideo: "Insira um link de youtube",
			changeAudio: "Clique para adicionar um ficheiro de áudio ou arraste o ficheiro para esta caixa.",
			everyF: "Todas",
			uploadImages: "Coloque imagens aqui ou clique para selecionar os ficheiros para o upload",
			uploadVideos: "Coloque os videos aqui ou clique para selecionar os ficheiros para o upload",
			none: "Nenhum",
			archiveMessage: "Tem a certeza de que pretende arquivar este item?",
			archive: "Arquivar",
		},

		fields: {
			name: "Nome",
			firstName: "Primeiro Nome",
			lastName: "Último Nome",
			centername: "Nome de Centro",
			address: "Morada",
			address2: "Morada 2",
			age: "Idade",
			addresses: "Moradas",
			country: "País",
			state: "Estado",
			city: "Cidade",
			zipCode: "Código Postal",
			description: "Descrição",
			phone: "Telefone",
			phoneNumber: "Número de Telefone",
			phoneShort: "Tel.:",
			mobilePhone: "Telemóvel",
			email: "Email",
			seoUrl: "SEO Url",
			tags: "Tags",
			ccNumber: "Número do CC",
			billingAddress: "Morada de Faturação",
			shippingAddress: "Morada de Envio",
			vat: "VAT",
			password: "Password",
			message: "Mensagem",
			image: "Imagem",
			images: "Imagens",
			imgPlaceholder: "Tente arrastar imagens para aqui, ou clique para fazer upload",
			filesPlaceholder: "Tente arrastar o ficheiro para aqui ou clique para selecionar o ficheiro",
			subTotal: "Subtotal",
			total: "Total",
			quantity: "Quantidade",
			ordered: "Qtd. Encomendada",
			totalQuantity: "Quantidade Total",
			discount: "Desconto",
			registerDate: "Data Registo",
			invalidEmail: "Email não é valido",
			tax: "Taxa",
			addedOn: "Adicionado",
			startDate: "Data Inicial",
			endDate: "Data Final",
			startHour: "Hora Inicial",
			endHour: "Hora Final",
			filename: "Nome do Ficheiro",
			openFile: "Abrir Ficheiro",
			gender: "Género",
			waitingForResults: "À espera do nome ou SKU...",
			link: "Link",
			date: "Data",
			otherRegions: "Outras Zonas",
			taxValues: "Taxas",
			previewLink: "Link de Pré-Visualização",
			clickToUpload: "Clique para fazer o Upload",
			photo: "Foto",
			autor: "Autor",
			category: "Categoria",
			number: "Número",
			notes: "Notas / Observações",

			// Select
			selectCountry: "Selecionar País",
			selectState: "Selecionar Estado",
			selectFilters: "Selecionar Filtros",
			selectDiscount: "Selecionar Desconto",

			countries: "Países",
			states: "Estados",

			// Pairs
			valuePair: "{0} / {1}",
			placeHolderPair: "{0} => {1}",

			code: "Abreviatura",
			bccEmail: "Email para receber encomendas",
			article: "Artigo",

			logo: "Logo",
			gif: "GIF",
			file: "Ficheiro"
		},

		language: {
			header: "Idioma",
			defaultLanguage: "Idioma Padrão",
			languageNotActive: "Guarde as atualizações antes de proceder à alteração do idioma padrão.",
			portuguese: "Português",
			english: "Inglês",
			german: "Alemão",
			french: "Francês",
			spanish: "Espanhol"
		},

		sidebar: {
			dashboard: "Dashboard",
			profile: "Perfil",
			information: "Informação",
			staff: "Colaboradores",
			settings: "Definições",
			logs: "Logs",
			users: "Utilizadores",
			notifications: "Notificações",
			clients: "Clientes",
			vehicles: "Veículos"
		},

		header: {
			daysLeft: "dias restantes de trial",
			upgrade: "Upgrade",
			account: "Conta",
			maintenance: "Manutenção",
			visitStore: "Visitar Loja",
			deleteSuccess: "Dados Temporários apagados com sucesso",
			extend: "Aumentar Trial"
		},

		// Screens
		authentication: {
			welcome: "Bem-vindo",
			header: "Autenticação",
			register: "Registo",
			login: "Entrar",
			logout: "Sair",
			signIn: "Autenticar",
			confirmAccount: "Confirmar Conta",
			name: "Nome",
			namePlaceholder: "O seu nome",
			email: "Email",
			emailPlaceholder: "O seu email",
			password: "Password",
			passwordPlaceholder: "A sua password",
			newPassword: "Nova password",
			newPasswordPlaceholder: "Inserir nova password",
			confirmPassword: "Confirmar Password",
			confirmPasswordPlaceholder: "Repetir a password",
			forgotPassword: "Esqueceu-se da password?",
			loginFooter: "Não tem conta? Registe-se aqui",
			registerFooter: "Já tem conta? Autentique-se aqui.",
			resendCode1: "Não recebeu o seu código de confirmação? ",
			resendCode2: "Reenviar código de ativação",
			sendEmail: "Enviar Email",
			recoverPassword: "Recuperar password",
			changePassword: "Mudar Password",
			changeEmail: "Mudar Email",
			newEmail: "Novo Email",
			newEmailConfirm: "Confirmar Novo Email",
			repeatEmail: "Repetir Email",
			currentEmail: "Email Atual",
			currentPassword: "Password Atual",
			emailSent: "Email Enviado com Sucesso",
			repeatPassword: "Repetir Password",
			rememberedPassword: "Lembrou-se da password?",
			adminPassword: "Password do Administrador",
			notLoggedIn: "Necessita de estar autenticado para ter acesso a esta página",
			noMatchPassword: "As passwords não coincidem",
			acceptSubtitle: "Por favor insira uma password para confirmar a sua conta.",
			confirmInvite: "Por favor insira a sua password para aceitar o convite.",
			goBackToLogin: "Ir para o ecrã de início de sessão",
			resendInviteEmail: "Reenviar email de convite",
			confirmResendInviteEmail: "Reenviar email de convite para {0}?",
			forgetTitle:
				"Tem a certeza que deseja esquecer o utilizador {0}? Esta ação irá deixar o utilizar sem acesso à app, e todos os dados serão removidos.",
			forgetConfirm: "Forget User",
			enterCode: "Introduza o authy code"
		},

		acceptInvite: {
			header: "Aceitar Convite",
			title: "Olá {0}, {1} convidou-o(a) a pertencer a {2}.",
			title2: "Olá {0}, {1} convidou-o(a) para gerir {2}.",
			subTitle: "Insira uma password para confirmar a sua conta",
			expired: "Este convite expirou"
		},

		notFound: {
			header: "Página Inexistente"
		},

		importModal: {
			fileName: "Nome do Ficheiro",
			size: "Tamanho",
			import: "Importar"
		},

		errors: {
			invalidArgs: "Argumentos inválidos ou em falta",
			invalidFields: "Campos inválidos ou em falta",
			emailIsInvalid: "O formato de email inserido não é válido",
			fillEmail: "Insira um email por favor",
			fillPassword: "Insira uma password por favor",
			pleaseFillFormCorrectly: "Por favor preencha o formulário correctamente",
			fillField: "Este campo é de preenchimento obrigatório",
			notSupportedFile: "Tipo de ficheiro não suportado",
			nameIsEmpty: "O campo nome é de preenchimento obrigatório",
			passwordsDontMatch: "Os campos 'Nova password' e 'Confirmar password' não coincidem",
			emailIsEmpty: "O campo email é de preenchimento obrigatório",
			fileTooBigorInvalid: "O tamanho da imagem é superior a 8MB ou tem um formato inválido",
			unsupportedFile: "Ficheiro não suportado",
			fileNotSupported: "O ficheiro que tentou carregar, não é suportado.",
			processingError: "Erro de Processamento",
			imageProcessingError: "{images} não puderam ser processadas.",
			tempIsInvalid: "O formato de temperatura inserida não é válida",
			fillTemperature: "Insira uma temperatura por favor",
			fahrTemperature: "Temperature should be between 32 and 113",
			celsTemperature: "Temperature should be between 0 and 45",
			fillValuesNumbers: "Please fill all values with numbers.",
			minMaxDifferent: "Minimum and Maximum should be different.",
			maxHigherThanMin: "Minimum should be lower than Maximum.",
			duplicateAIValue: "Duplicate Values for AI found."
		},

		profile: {
			profilePicture: "Foto de Perfil",
			role: "Cargo",
			editedSuccessfully: "Perfil editado com sucesso",
			recurrence: "Recorrência",
			recurrenceActive: "Ativar Recorrência",
			recurrenceUntil: "Recorrente até",
			recurringEndMissing: "A data final de recorrência é obrigatória.",
			recurrenceSuccess: "Definições de recorrência guardadas com sucesso.",
			deleteRecurrence: "Eliminar recorrência",
			noThankYou: "Não Obrigado(a)",
			recurrenceTooltip: "Criar recorrência",
			deleteRecurrenceDescription: "Pretende eliminar a recorrência do dia {date}?"
		},

		placeholders: {
			name: "O seu nome",
			email: "o_seu@email.com",
			phoneNumber: "+351265000000",
			language: "Selecione uma língua",
			role: "Selecione um cargo",
			currentPassword: "password actual",
			newPassword: "nova password",
			confirmPassword: "nova password",
			newEmail: "novo@email.com",
			page: "pagina",
			titlePage: "Título da Página",
			link: "https://www.google.com/"
		},

		staff: {
			admin: "Administrador",
			mechanic: "Mecânico",
			driver: "Motorista",
			helper: "Ajudante",
			helpers: "Ajudante(s)",
			helper2: "Ajudante 2",
			helper3: "Ajudante 3",
			type: "Função",
			staff: "Colaborador",
			editedSuccessfully: "Colaborador editado com sucesso",
			passwordChangedSuccessfully: "Password alterada com sucesso",
			emailChangedSuccessfully: "Email alterado com sucesso",
			wrongPassword: "Password incorreta",
			emailInUse: "Email em uso",
			status: "Estado",
			confirmed: "Confirmado",
			pending: "Pendente",
			createStaff: "Criar Colaborador",
			createdSuccessfully: "Colaborador criado com sucesso.",
			sysAdmin: "System Admin",
			addStaff: "Adicionar um colaborador",
			editStaff: "Editar Colaborador",
			addOwner: "Adicionar um Administrador",
			editOwner: "Editar um Administrador",
			user: "User",
			removeStaff: "Remover Colaborador",
			removed: "Colaborador removido com sucesso.",
			disableStaff: "Inativar Colaborador",
			enableStaff: "Ativar Colaborador",
			disabled: "Colaborador desativado com sucesso.",
			enabled: "Colaborador ativado com sucesso.",
			addNew: "Adicionar novo Colaborador",
			documents: "Documentos",
			selectStaff: "Selecione Colaborador",
			costs: "Custos Salário",
			startDate: "Data Inicial",
			grossSalary: "Salário Bruto",
			socialSecuriyPercentage: "Segurança Social %",
			extraSalary: "Valor Extra",
			mealAllowance: "Subs. Alimentação",
			companyCar: "Carro Empresa",
			healthInsurance: "Seguro Saúde",
			overheads: "Overheads",
			annualCost: "Custo Anual",
			dailyCost: "Custo Diário",
		},

		ranges: {
			today: "Hoje",
			thisMonth: "Este mês",
			lastMonth: "Último mês",
			thisYear: "Este ano",
			lastYear: "Último ano"
		},

		dashboard: {
			period: "Período",
			active: "Ativo",
			homepage: "Homepage",
			inStock: "Em Stock",
			daily: "Diário",
			monthly: "Mensal",
			kmUse: "Total Km",
			kmDetails: "Km por Data",
			hoursUse: "Total Horas",
			hoursDetails: "Horas por Data",
			fuelUse: "Valor Combustível",
			fuelLiters: "Litros Combustível",
			fuelDetails: "Combustível por Data",
			costsUse: "Total Custos",
			workValues: "Valor Trabalhos",
			revenueAnalysis: "Valor Total Trabalhos",
			extraWorksUse: "Total Trabalhos Extras",
			myFinishedWorks: "Meus trabalhos Finalizados",
			costsDetails: "Custos por Data",
			costsResume: "Resumo dos Custos",
			fuelResume: "Resumo dos Abastecimento",
			selectDriver: "Motorista",
			selectVehicle: "Viatura",
			selectVehicleNumber: "Nº Carro",
			selectCustomer: "Cliente"
		},

		settings: {
			pages: "Páginas",
			emailTemplates: "Templates de E-mails",
			pagesSubTitle:
				"In this area you will be able to change the content of pages, like Terms & Condition and Privacy Policy",
			emailsSubTitle:
				"Setup email templated, used to sent emails to users, and also used to manage staff invitation",
			appSettings: "App Settings",
			appSettingsTitle: "Change the general settings of your app",
			appConfigurations: "App Configurations",
			primaryColor: "Primary Color",
			gradientStart: "Gradient Color Start",
			gradientEnd: "Gradient Color End",
			pickColor: "Click to pick a color",
			imageColor: "Image Color",
			imageWhite: "Image White",
			splashImage: "Image to use on Spashscreen",
			register: "Allow User Registration",
			socialLogin: "Use Social Login",
			sentry: "App Sentry Link",
			storeLinkAndroid: "Android Store Link",
			storeLinkIos: "iOS Store Link",
			warningVersionsAndroid: "Android Warning Version",
			criticalVersionsAndroid: "Android Critical Version",
			warningVersionsIos: "iOS Warning Version",
			criticalVersionsIos: "iOS Critical Version",
			borderRadius: "Border Radius",
			expensesTypes: "Tipos de Custo",
			expensesTypesTitle: "Defina tipos de custo",
			selectPdf: "Selecionar PDF",
		},

		pages: {
			single: "Página",
			title: "Título",
			content: "Conteúdo",
			new: "Nova Página",
			isLink: "Redirecionamento"
		},

		templates: {
			single: "Template"
		},

		emails: {
			single: "E-mail",
			tags: "Tags",
			preview: "Pré-visualização",
			subject: "Assunto",
			cc: "CC",
			templates: "Templates de E-mail",
			invite: "Convidar Staff",
			edited: "Template de E-mail editado com sucesso."
		},

		serverErrors: {
			title: "Erro de Servidor",
			subTitle: "Não é você, somos nós!",
			refresh: "Voltar à Página Anterior",
			backToDashboard: "Ir para o Dashboard",
			wentWrong: "Algo correu mal."
		},

		logs: {
			dateRange: "Date Range",
			userId: "User Id",
			url: "Url",
			token: "Token",
			codes: "Codes",
			methods: "Methods",
			sources: "Sources",
			types: "Types"
		},

		emailTemplates: {
			generalEmail: "E-mail Genérico",
			accountConfirmationCode: "Confirmação de Conta Utilizador por Código",
			recoverPasswordCode: "Recuperação de Password por Código",
			accountConfirmationLink: "Confirmação de Conta Utilizador por Link",
			recoverPasswordLink: "Recuperação de Password por Link",
			reservationEmail: "E-mail de Confirmação de Reserva",
			staffAccountConfirmation: "Confirmação de Conta de Acesso ao BO",
			staffRecoverPassword: "Recuperação de Password de Acesso ao BO",
			supportEmail: "Support E-mail",
			resetPassword: "Reset Password (BO)",
			generalCleanWithoutButton: "General Clean Without Button",
			generalCleanWithButton: "General Clean With Button",
			generalWithCode: "General With Code",
			confirmAccount: "Confirm Account (BO)",
			welcome: "Welcome E-mail",
			generalWithLink: "General With Link"
		},

		notifications: {
			new: "Nova Notificação",
			title: "Título da Notificação",
			message: "Texto da Notificação",
			email: "Conteúdo do E-mail",
			date: "Date de Envio",
			sendEmail: "Enviar E-mail",
			created: "A notificação foi criada com sucesso.",
			edited: "A notificação foi editada com sucesso.",
			deleted: "A notificação foi apagada com sucesso.",
			missingTitle: "Título é um campo de preenchimento obrigatório.",
			missingDescription: "Descrição é um campo de preenchimento obrigatório.",
			missingDate: "Date de Envio é um campo de preenchimento obrigatório.",
			missingEmailContent: "Conteúdo do E-mail é um campo de preenchimento obrigatório.",
			notificationText: "Notification Text"
		},

		users: {
			header: "Utilizador",
			fullInfo: "Informação do Utilizador",
			addUser: "Adicionar um Utilizador",
			editUser: "Editar um Utilizador",
			title: "Utilizadores",
			kickTitle: "Utilizadores expulsos",
			solveKick: "Resolução da Expulsão",
			session: "Sessão",
			reason: "Reason"
		},

		customers: {
			new: "Novo cliente",
			customer: "Cliente",
			noLogo: "Este cliente ainda não tem logotipo",
			contacts: "Contactos"
		},

		vehicles: {
			new: "Novo veículo",
			vehicle: "Veículo",
			licensePlate: "Matricula",
			type: "Tipo",
			truck: "Camião",
			van: "Carrinha",
			car: "Carro",
			service: "Revisão",
			services: "Revisões",
			kms: "Quilometragem",
			viaVerde: "Via Verde",
			uploadViaVerde: "Upload Via Verde",
			uploadFile: "Clique ou arraste o ficheiro da Via Verde para aqui",
			avarage: "Média",
		},

		worksheets: {
			worksheet: "Ordem de trabalho",
			worksheets: "Ordens de trabalho",
			generalInfo: "Informação Geral",
			new: "Nova folha de trabalho",
			number: "Número Trabalho",
			exitHour: "Hora de início",
			returnHour: "Hora de fim",
			exitRegistration: "Registo de saída",
			arrivalRegistration: "Registo de chegada",
			createWorksheet: "Criar ordem de trabalho",
			finishWorksheet: "Acabar ordem de trabalho",
			validation: "Em Validação",
			validate: 'Validar',
			validateAll: 'Validar Todos',
			pending: "Pendente",
			started: "Iniciada",
			finished: "Terminada",
			invalidHour: "A hora de regresso é inferior à hora de inicio, deverá corrigir a hora e submeter novamente.",
			invalidKm: "O número de km indicado é inferior ao número de km do último serviço desta viatura.",
			invalidRefuelKm: "O número de km indicado é inferior ao número de km do inicio do serviço.",
			fillFields: "Por favor preencha todos os campos obrigatórios.",
			costs: "Custos",
			refuels: "Abastecimentos",
			amount: "Valor",
			amountAdBlue: "Valor AdBlue",
			notes: "Notas",
			costType: "Tipo de custo",
			status: "Estado",
			customerNumber: "Nº Rota",
			origin: "Origem",
			destination: "Destino",
			route: "Percurso",
			withoutVehicle: "Trabalho sem viatura",
			vehicleNumber: "Nº Carro",
			vehiclesNumber: "Nº Viaturas",
			internalWork: "Trabalho Interno",
			workValue: "Valor Trabalho",
			workValueExtra: "Valor Trabalho Extra",
			extraWork: "Trabalho Extra (Staff)",
			extraWorkValue: "Valor Extra (Staff)",
			workValueExtraShort: "Extra",
			extraWorkValueShort: "Extra (Staff)",
			extraWorkValueShortNew: "Extra Novo (Staff)",
			totalKm: "Total de Kms",
			impossibleMileage: "O número de quilometros feitos não é válido.",
			pendingWorksheets: "Ordens de trabalho pendentes",
			notDefined: "Ainda não definido",
			noneAvailable: "Não existem ordens de trabalho pendentes.",
			exitKm: "Km Início",
			returnKm: "Km Fim",
			liters: "Litros",
			litersAdBlue: "Litros AdBlue",
			dailyView: "Visão Diária",
			sendValidation: "Alterar folha de trabalho de em validação para pendente? {0}",
			sendValidationAll: "Alterar todas as folha de trabalho de em validação para pendente? {0}",
			toll: "Portagens",
			entry: "Entrada",
			exit: "Saida",
			staffWithoutWork: "Colaboradores sem Alocação",
			cloneDate: "Selecione a data para qual pretende clonar as folhas de trabalho",
		}
	}
});
